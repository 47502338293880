import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  StepButtons,
  StepPanel,
} from '@stichting-allianceblock-foundation/components';
import { ReactComponent as AddLiquidityDesktopWallpaper } from 'assets/add-liquidity-desktop.svg';
import { ReactComponent as AddLiquidityMobileWallpaper } from 'assets/add-liquidity-desktop.svg'; // TODO: Change with mobile svg
import { useBreakpoint } from 'hooks/useBreakpoint';
interface InitialStepProps {
  setActiveStep: (step: number) => void;
}

const InitialStep = ({ setActiveStep }: InitialStepProps) => {
  const { t } = useTranslation();
  const { width, greaterThan } = useBreakpoint();

  const renderStep = () => {
    return (
      <div className="mt-8 mb-5 my-md-7">
        <div className="svg-wrapper-100 d-flex justify-content-center mt-4">
          {greaterThan('md') ? (
            <AddLiquidityDesktopWallpaper className="max-width-100" />
          ) : (
            <AddLiquidityMobileWallpaper className="max-width-100" />
          )}
        </div>

        <div className="mt-4 mb-5">
          <div className="text-medium text-bold text-main text-center mb-3">
            {t('addLiquidity:title')}
          </div>
          <div className="text-center mb-3">
            <Trans i18nKey="addLiquidity:subtitle" components={{ span: <span /> }} />
          </div>
          <div className="text-center">
            <Trans i18nKey="addLiquidity:description" components={{ span: <span /> }} />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button type="primary" onClick={() => setActiveStep(1)}>
            <span>{t('addLiquidity:addLiquidityTitle')}</span>
            <Icon color="uiElementSecondary" size={20} name="arrow-right-long" className="ml-3" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-8">
      <StepPanel>
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          {renderStep()}
        </div>
      </StepPanel>
    </div>
  );
};

export default InitialStep;
