import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';

import { Step, Stepper } from '@stichting-allianceblock-foundation/components';

import './RemoveLiquidityStepper.scss';
import WithdrawAssets from './Steps/WithdrawAssets';

interface RemoveLiquidityStepperProps {
  currencyIdA?: string;
  currencyIdB?: string;
  history: any;
}

const RemoveLiquidityStepper = ({
  currencyIdA,
  currencyIdB,
  history,
}: RemoveLiquidityStepperProps) => {
  const { account, chainId, library } = useWeb3React();
  const [activeStep, setActiveStep] = useState(0);

  return (
    <div className="remove-liquidity-stepper my-4">
      <Stepper>
        <Step activeStep={activeStep} stepNumber={0} subtitle="" title="">
          <WithdrawAssets currencyIdA={currencyIdA} currencyIdB={currencyIdB} history={history} />
        </Step>
      </Stepper>
    </div>
  );
};

export default RemoveLiquidityStepper;
