import { Token } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { usePairContract } from '../hooks/useContract';
import { useSingleCallResult } from '../state/multicall/hooks';

export function PoolEmpty(token?: Token): Boolean | undefined {
  const contract = usePairContract(token?.address, false);
  const isEmpty: Boolean = useSingleCallResult(contract, 'isEmpty')?.result?.[0];

  return isEmpty;
}
