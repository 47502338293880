import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Indicator } from 'components/Indicator';
import { useBreakpoint } from 'hooks/useBreakpoint';

interface Counter {
  count: number | undefined;
}

function Counter({ count }: Counter) {
  const { t } = useTranslation();
  const { lessThan } = useBreakpoint();

  return (
    <div className="mb-4 d-flex">
      <Indicator
        type="primary"
        title="My pools"
        value={count ?? 0}
        className="mr-2 mr-sm-0 mb-sm-2"
      />
    </div>
  );
}

export default Counter;
