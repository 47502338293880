import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
// import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import { NETWORK_CONFIG } from 'configs/networks';
import { ethers } from 'ethers';

const POLLING_INTERVAL: number = 12000;
const SUPPORTED_CHAINIDS: number[] | undefined = Object.values(NETWORK_CONFIG)?.map(
  (config: any): number => config.network.chainId,
);

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider, 'any');
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

export const injectedConnector = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAINIDS,
});

// TODO: ADD ALL NETWORKS
export const walletConnect = new WalletConnectConnector({
  rpc: {
    80001: 'https://polygon-mumbai.infura.io/v3/6cc519519e394bf787e6142617dbbcc3',
    246: 'https://rpc.energyweb.org/',
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

// export const walletlink = new WalletLinkConnector({
//   url: 'https://polygon-mumbai.infura.io/v3/6cc519519e394bf787e6142617dbbcc3',
//   appName: 'AllianceDEX',
//   appLogoUrl:
//     'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg',
// });

export enum ConnectorNames {
  Injected,
  WalletConnect,
  // WalletLink,
}

export const connectorsByName: {
  [connectorName in ConnectorNames]: any;
} = {
  [ConnectorNames.Injected]: injectedConnector,
  [ConnectorNames.WalletConnect]: walletConnect,
  // [ConnectorNames.WalletLink]: walletlink,
};
