import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CurrencyAmount, JSBI } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { Trade } from '@stichting-allianceblock-foundation/abdex-trade-sdk';
import {
  Button,
  Icon,
  LabelButton,
  StepPanel,
} from '@stichting-allianceblock-foundation/components';
import { Attention } from 'components/Attention';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';
import { CurrencyInputPanel } from 'components/CurrencyInputPanel';
import { EstimationFee } from 'components/EstimationFee';
import { SwapTransactionInfo } from 'components/SwapTransactionInfo';
import { Title } from 'components/Title';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback';
import { useCoinGeckoList } from 'hooks/useCoinGeckoList';
import useENSAddress from 'hooks/useENSAddress';
import { useIsTransactionUnsupported } from 'hooks/useIsTransactionUnsupported';
import { useSwapCallback } from 'hooks/useSwapCallback';
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback';
import { AppDispatch } from 'state';
import { useCurrentNetwork } from 'state/application/hooks';
import { Field, typeInput } from 'state/swap/actions';
import { useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from 'state/swap/hooks';
import { useUserSingleHopOnly, useUserSlippageTolerance } from 'state/user/hooks';
import { maxAmountSpend } from 'utils';
import { getTokenPrice } from 'utils/coingecko';
import { computeTradePriceBreakdown, warningSeverity } from 'utils/prices';

import './SwapAssets.scss';

interface SwapAssetsProps {
  currencyIdA?: string;
  currencyIdB?: string;
  history: any;
}

const SwapAssets = ({ currencyIdA, currencyIdB, history }: SwapAssetsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { account, chainId, library } = useActiveWeb3React();
  const { t } = useTranslation();
  const [isActive, setActive] = useState<boolean>(false);
  const currentNetwork = useCurrentNetwork();
  const programmaticHistory = useHistory();
  const coinGeckoList = useCoinGeckoList();
  const [tokenOutputFiatPrice, setTokenOutputFiatPrice] = useState<number>(0);

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();

  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    isFetching,
    inputError: swapInputError,
  } = useDerivedSwapInfo();

  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue,
  );

  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const { address: recipientAddress } = useENSAddress(recipient);

  const trade = showWrap ? undefined : v2Trade;
  const defaultTrade = showWrap ? undefined : v2Trade;

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();

  const isValid = !swapInputError;
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string, currency: any) => {
      onUserInput(Field.INPUT, value, currency);
    },
    [onUserInput],
  );

  const handleTypeOutput = useCallback(
    (value: string, currency) => {
      onUserInput(Field.OUTPUT, value, currency);
    },
    [onUserInput],
  );

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState<{
    showConfirm: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0)),
  );
  const noRoute = !route;

  // check whether the user has approved the router on the input token
  const [approval, approveHash, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage,
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT]);
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput),
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient,
  );

  const [singleHopOnly] = useUserSingleHopOnly();
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade],
  );
  const severity = warningSeverity(priceImpactWithoutFee);

  const handleSwap = useCallback(() => {
    // if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
    //   return;
    // }
    if (!swapCallback) {
      return;
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
    });
    swapCallback()
      .then(response => {
        setSwapState({
          attemptingTxn: true,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: response.hash,
        });

        response.wait().then(() => {
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: undefined,
            txHash: response.hash,
          });

          dispatch(
            typeInput({
              field: independentField,
              typedValue: '',
            }),
          );
        });
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        });
      });
  }, [
    swapCallback,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly,
  ]);

  const handleWrap = useCallback(() => {
    console.log('Wrapping', onWrap);
    if (!onWrap) {
      return;
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
    });
    onWrap()
      .then(response => {
        setSwapState({
          attemptingTxn: true,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: response.hash,
        });

        response.wait().then(() => {
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: undefined,
            txHash: response.hash,
          });

          dispatch(
            typeInput({
              field: independentField,
              typedValue: '',
            }),
          );
        });
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        });
      });
  }, [
    swapCallback,
    onWrap,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly,
  ]);

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
    },
    [onCurrencySelection],
  );

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact(), currencies[Field.INPUT]);
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection],
  );

  useEffect(() => {
    const tokenIdOutput = coinGeckoList
      .filter((item: any) => item.symbol === currencies[Field.OUTPUT]?.symbol?.toLowerCase())
      .map((item: any) => item.id);

    const getPrices = async () => {
      if (tokenIdOutput[0]) {
        const tokenOutputPrice = await getTokenPrice(tokenIdOutput[0], 'usd');
        setTokenOutputFiatPrice(tokenOutputPrice);
      } else {
        setTokenOutputFiatPrice(0);
      }
    };
    getPrices();
  }, [coinGeckoList, currencies[Field.OUTPUT]]);

  const renderStep = () => {
    return (
      <div className="mt-8 mb-5 my-md-7">
        <div className={`pairs-wrapper p-6 ${isActive ? 'info-activated' : ''}`}>
          <h2 className="text-subtitle text-bold">Swap</h2>
          {/* <div className="text-main">
            <Trans i18nKey={'swap:subtitle'} components={{ span: <span /> }} />
          </div> */}
          <div className="mt-4 d-flex flex-column flex-md-row align-items-top">
            <CurrencyInputPanel
              label={
                independentField === Field.OUTPUT && !showWrap && trade
                  ? 'From (estimated)'
                  : 'From'
              }
              value={formattedAmounts[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              currency={currencies[Field.INPUT]}
              id="swap-currency-input"
              showCommonBases
              assetLabel="From"
              otherCurrency={currencies[Field.OUTPUT]}
            />
            <Icon
              className={`align-self-center ${
                isValid ? 'edit-between m-4 valid' : 'edit-between m-4'
              }`}
              color="ui-secondary"
              name="staking-swap"
              onClick={() => {
                onSwitchTokens();
              }}
              size={24}
            ></Icon>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={
                independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'
              }
              onCurrencySelect={handleOutputSelect}
              currency={currencies[Field.OUTPUT]}
              id="swap-currency-output"
              showCommonBases
              assetLabel="To"
              hideSlider={true}
            />
          </div>

          <div className="m-5 controls d-flex align-items-center justify-content-center">
            {(approval === ApprovalState.NOT_APPROVED || approval === ApprovalState.PENDING) &&
              isValid && (
                <div className="approvals-wrapper d-flex flex-column flex-md-row">
                  <div className="m-3 approval">
                    {approval !== ApprovalState.PENDING ? (
                      <Button
                        size="md"
                        type="primary"
                        className="custom-disabled-style-button"
                        onClick={approveCallback}
                        // @ts-ignore
                        disabled={approval === ApprovalState.PENDING}
                      >
                        <Icon
                          className="mr-2"
                          color="ui-main-background"
                          name="nav-ok-s"
                          size={24}
                        />
                        <>Approve {currencies[Field.INPUT]?.symbol} spending</>
                      </Button>
                    ) : (
                      <LabelButton
                        type="primary"
                        loading={true}
                        loadingText={t('addLiquidity:processingText')}
                        label={
                          approveHash ? (
                            <BlockExplorerBadge // TODO: Make it dynamically
                              title={'Etherscan'}
                              hash={approveHash}
                              blockExplorer={currentNetwork.blockExplorerUrl}
                              type="tx"
                            />
                          ) : (
                            <span className="text-center">
                              {t('addLiquidity:waitingForConfirmationText')}
                            </span>
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            {isValid &&
              (showWrap || approval === ApprovalState.APPROVED) &&
              (!attemptingTxn ? (
                showWrap ? (
                  <Button
                    size="md"
                    type="primary"
                    className="custom-disabled-style-button"
                    onClick={handleWrap}
                  >
                    <Icon
                      className="mr-2"
                      color="ui-main-background"
                      name="staking-swap"
                      size={24}
                    />
                    <span>{wrapType === WrapType.WRAP ? 'Wrap' : 'Unwrap'}</span>
                  </Button>
                ) : (
                  <Button
                    size="md"
                    type="primary"
                    className="custom-disabled-style-button"
                    onClick={handleSwap}
                  >
                    <Icon
                      className="mr-2"
                      color="ui-main-background"
                      name="staking-swap"
                      size={24}
                    />
                    <span>Swap</span>
                  </Button>
                )
              ) : (
                <LabelButton
                  type="primary"
                  loading={true}
                  loadingText={t('addLiquidity:processingText')}
                  label={
                    txHash ? (
                      <BlockExplorerBadge // TODO: Make it dynamically
                        title={'Etherscan'}
                        hash={txHash}
                        blockExplorer={currentNetwork.blockExplorerUrl}
                        type="tx"
                      />
                    ) : (
                      <span className="text-center">
                        {t('addLiquidity:waitingForConfirmationText')}
                      </span>
                    )
                  }
                />
              ))}
          </div>
          {swapInputError && (
            <Attention title={'Attention:'} description={swapInputError} color={true}></Attention>
          )}
          {!showWrap && noRoute && !swapInputError && userHasSpecifiedInputOutput && (
            <Attention
              title={'Attention:'}
              description={'Insufficient Liquidity for this trade'}
              color={true}
            ></Attention>
          )}
          {isValid && severity >= 3 && !noRoute && (
            <Attention
              title={'Attention:'}
              description={'Price impact is very high!'}
              color={true}
            ></Attention>
          )}
          {/* {isValid && !isActive && (
            <EstimationFee txFeeEstimation="123" txFeeEstimationCurrency="123" />
          )} */}
        </div>
        <div className="info-wrapper d-flex justify-content-center">
          {!isActive && (
            <Button
              size="sm"
              type="secondary"
              className="info-button"
              onClick={() => setActive(!isActive)}
              disabled={!isValid}
            >
              Info
              <Icon className="ml-2" color="brand-primary" name="chevron-double-down" size={14} />
            </Button>
          )}
        </div>
        {isActive && (
          <>
            <div className="p-4 panel">
              <SwapTransactionInfo
                currencies={currencies}
                tokenOutputFiatPrice={tokenOutputFiatPrice}
                trade={trade}
              ></SwapTransactionInfo>
            </div>
            <div className="d-flex justify-content-center info-wrapper">
              {isActive && (
                <Button
                  size="sm"
                  type="secondary"
                  className="info-button"
                  onClick={() => setActive(!isActive)}
                >
                  Info
                  <Icon className="ml-2" color="brand-primary" name="chevron-double-up" size={14} />
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="mt-8">
      <StepPanel>
        <div className="d-flex flex-column justify-content-md-between">{renderStep()}</div>
      </StepPanel>
    </div>
  );
};

export default SwapAssets;
