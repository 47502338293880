import React from 'react';
import { useEffect, useState } from 'react';

import { NETWORK_CONFIG } from 'configs/networks';

export const useNetworkOptions = () => {
  const [networkOptions, setNetworkOptions] = useState<Network[]>([]);

  useEffect(() => {
    const loadNetworkOptions = () => {
      const networkOptions_ = Object.values(NETWORK_CONFIG)?.map((item: any) => item?.network);
      setNetworkOptions(networkOptions_);
    };

    loadNetworkOptions();
  }, []);

  return networkOptions;
};
