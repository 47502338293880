import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  ChainId,
  Currency,
  currencyEquals,
  Token,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { InfoTooltip } from 'components/Tooltips';
import { NATIVE_CURRENCY, SUGGESTED_BASES } from 'configs/constants';
import { Text } from 'rebass';

import { AutoColumn } from '../Column';
import CurrencyLogo from '../CurrencyLogo';
import { AutoRow } from '../Row';

import './CommonBases.scss';

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId;
  selectedCurrency?: Currency | null;
  onSelect: (currency: Currency) => void;
}) {
  const { t } = useTranslation();

  return (
    <AutoColumn gap="ssm">
      <AutoRow gap="4px">
        <div className="asset-info d-flex">
          <p className="text-small text-secondary text-bold">{t('addLiquidity:commonBases')}</p>
        </div>
      </AutoRow>
      <AutoRow gap="4px">
        <div
          className={`base-wrapper d-flex ${
            selectedCurrency === NATIVE_CURRENCY[chainId as ChainId] ? 'disabled' : ''
          }`}
          onClick={() => {
            if (
              !selectedCurrency ||
              !currencyEquals(selectedCurrency, NATIVE_CURRENCY[chainId as ChainId])
            ) {
              onSelect(NATIVE_CURRENCY[chainId as ChainId]);
            }
          }}
        >
          <CurrencyLogo currency={NATIVE_CURRENCY[chainId as ChainId]} style={{ marginRight: 8 }} />
          <p className="text-small text-bold">{NATIVE_CURRENCY[chainId as ChainId].symbol}</p>
        </div>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected =
            selectedCurrency instanceof Token && selectedCurrency.address === token.address;
          return (
            <div
              className={`base-wrapper d-flex ${selected ? 'disable' : ''}`}
              onClick={() => !selected && onSelect(token)}
              key={token.address}
            >
              <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
              <p className="text-small text-bold">{token.symbol}</p>
            </div>
          );
        })}
      </AutoRow>
    </AutoColumn>
  );
}
