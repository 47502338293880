import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@stichting-allianceblock-foundation/components';
import { NetworkContextName } from 'configs/constants';
import { getLibrary } from 'utils/web3React';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './state';
import ApplicationUpdater from './state/application/updater';
import MulticallUpdater from './state/multicall/updater';
import SdkUpdater from './state/sdk/updater';
import TransactionUpdater from './state/transactions/updater';

import './index.scss';
import './global.scss';
import './tailwind.css';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <SdkUpdater />
    </>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <Updaters />
          <ThemeProvider theme="light">
            <App />
          </ThemeProvider>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
