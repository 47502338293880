export const config = {
  ewcMain: {
    network: {
      symbol: 'EWC',
      chainId: 246,
      chainName: 'Energy Web Chain',
      chainIcon: 'ewc',
      chainTargetId: 5,
      nativeCurrency: {
        name: 'EWT',
        symbol: 'EWT',
        decimals: 18,
      },
      rpcUrl: 'https://ewc-mainnet.alliancenode-dev.net',
      blockExplorerUrl: 'https://explorer.energyweb.org',
      refreshClaimTimestamp: 15000,
    },
  },
  polygon: {
    network: {
      symbol: 'MATIC',
      chainId: 80001,
      chainName: 'Polygon - Mumbai',
      chainIcon: 'matic',
      chainTargetId: 4,
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrl: 'https://rpc-mumbai.maticvigil.com',
      blockExplorerUrl: 'https://explorer-mumbai.maticvigil.com',
      refreshClaimTimestamp: 15000,
    },
  },
  ewc: {
    network: {
      symbol: 'EWC',
      chainId: 73799,
      chainName: 'Energy Web Chain - Volta',
      chainIcon: 'ewc',
      chainTargetId: 5,
      nativeCurrency: {
        name: 'EWC',
        symbol: 'EWC',
        decimals: 18,
      },
      rpcUrl: 'https://volta-rpc.energyweb.org',
      blockExplorerUrl: 'https://volta-explorer.energyweb.org',
      refreshClaimTimestamp: 15000,
    },
  },
};
