import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Currency } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { Button, Icon, Slider } from '@stichting-allianceblock-foundation/components';
import { NumericalInput } from 'components/NumericalInput';
import CurrencyLogo from 'components/StyledComponents/CurrencyLogo';
import CurrencySearchModal from 'components/StyledComponents/SearchModal/CurrencySearchModal';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { calculateTokenAmountByPercentage, calculateTokenPercentageByAmount } from 'utils';

import { useCurrencyBalance } from '../../state/wallet/hooks';

import './CurrencyInputPanel.scss';

interface CurrencyInputPanelProps {
  value: string;
  onUserInput: (value: string, currency: Currency | null | undefined) => void;
  onMax?: () => void;
  showMaxButton?: boolean;
  label?: string;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  disableCurrencySelect?: boolean;
  hideBalance?: boolean;
  pair?: any | null;
  hideInput?: boolean;
  otherCurrency?: Currency | null;
  id: string;
  showCommonBases?: boolean;
  customBalanceText?: string;
  assetLabel?: string;
  hideSlider?: boolean;
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton = true,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  customBalanceText,
  assetLabel,
  hideSlider = false,
}: CurrencyInputPanelProps) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { account } = useActiveWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined);
  const [sliderPercentage, setSliderPercentage] = useState<number>(0);

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleSliderPercentage = (percentage: string) => {
    if (!selectedCurrencyBalance || !currency) {
      return;
    }
    const amount = calculateTokenAmountByPercentage(
      percentage,
      selectedCurrencyBalance,
      currency,
    ).toString();

    setSliderPercentage(Number(percentage));
    onUserInput(amount, currency);
  };

  useEffect(() => {
    if (!selectedCurrencyBalance || !currency) {
      return;
    }
    const percentage = calculateTokenPercentageByAmount(value, selectedCurrencyBalance, currency);
    setSliderPercentage(Number(percentage));
  }, [value]);

  return (
    <div className="pair">
      <div className="mb-2 amount-available d-flex justify-content-end">
        {!hideBalance && (
          <div className="amount d-flex align-items-center">
            <p className="mr-2 text-small text-secondary">Available:</p>
            <p className="mr-2 text-small text-bold">{selectedCurrencyBalance?.toSignificant(6)}</p>
            {currency && (
              <div className="mr-2 d-flex align-items-center">
                <CurrencyLogo currency={currency} size={'18px'} />
                <span className="ml-2 text-secondary">{currency.symbol}</span>
              </div>
            )}
          </div>
        )}
        {showMaxButton && (
          <Button
            className="custom-disabled-style-button"
            disabled={!selectedCurrencyBalance}
            size="sm"
            type="primary"
            onClick={onMax}
          >
            <span>{t('maxButton:label')}</span>
          </Button>
        )}
      </div>
      <div className="px-5 pt-4 pb-6 asset-wrapper">
        {assetLabel && (
          <div className="mb-2 asset-info d-flex">
            <p className="mr-3 text-small text-secondary text-uppercase text-bold">{assetLabel}</p>
          </div>
        )}
        <div className="p-3 asset-selection d-flex justify-content-between align-items-center">
          <Button
            className="token-select-button"
            size="md"
            type="primary"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true);
              }
            }}
          >
            {currency ? (
              <>
                <CurrencyLogo
                  currency={currency}
                  size={'24px'}
                  style={{ marginRight: 16, marginLeft: 16 }}
                />
                <span>{currency.symbol}</span>
              </>
            ) : (
              <>
                <Icon className="mr-4" color="ui-secondary" name="staking-new-stake" size={24} />
                <span>{t('tokenButton:label')}</span>
              </>
            )}
            {!disableCurrencySelect && (
              <Icon className="ml-4" color="ui-secondary" name="chevron-down" size={24} />
            )}
          </Button>
          <div className="m-5 horizontal-line"></div>

          <div className="amount-selected">
            <div className="mr-2 token-amount text-main text-bold">
              {hideInput ? (
                <p className="text-medium text-secondary text-bold">{value ? value : '0.0'}</p>
              ) : (
                <NumericalInput
                  value={value}
                  onUserInput={val => {
                    onUserInput(val, currency);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
      {!hideSlider && (
        <div className="slider">
          <Slider
            color="primary"
            step={1}
            value={sliderPercentage}
            onChange={handleSliderPercentage}
          />
        </div>
      )}
    </div>
  );
}
