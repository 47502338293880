import { Pools } from 'components/Pools';
import React from 'react';

export default function MyPools() {
  return (
    <>
      <Pools></Pools>
    </>
  );
}
