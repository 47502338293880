import { useMemo } from 'react';

import { Contract } from '@ethersproject/contracts';
import { ChainId } from '@stichting-allianceblock-foundation/abdex-sdk-v2';

import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS,
} from '../configs/abis/argent-wallet-detector';
import ENS_PUBLIC_RESOLVER_ABI from '../configs/abis/ens-public-resolver.json';
import ENS_ABI from '../configs/abis/ens-registrar.json';
import { ERC20_BYTES32_ABI } from '../configs/abis/erc20';
import ERC20_ABI from '../configs/abis/erc20.json';
import IPool from '../configs/abis/IPool.json';
import IRouter from '../configs/abis/IRouter.json';
import { MULTICALL_NETWORKS2, MULTICALL2_ABI } from '../configs/abis/multicall';
import WETH_ABI from '../configs/abis/weth.json';
import { getContract } from '../utils';
import { ENSREGISTRAR_ADDRESS, ROUTER_ADDRESS, WrappedNativeToken } from './../configs/constants';
import { useActiveWeb3React } from './useActiveWeb3React';

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): Contract | null {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useRouterContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(ROUTER_ADDRESS[chainId as ChainId], IRouter.abi, true);
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(
    chainId === ChainId.POLYGON ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false,
  );
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(ENSREGISTRAR_ADDRESS[chainId as ChainId], ENS_ABI, withSignerIfPossible);
}

export function useENSResolverContract(
  address: string | undefined,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible);
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

export function usePairContract(
  pairAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(pairAddress, IPool.abi, withSignerIfPossible);
}

// export function useMulticallContract(): Contract | null {
//   const { chainId } = useActiveWeb3React();
//   return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, true);
// }

export function useMulticall2Contract() {
  const { chainId } = useActiveWeb3React();
  return useContract(chainId && MULTICALL_NETWORKS2[chainId], MULTICALL2_ABI, false);
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(
    chainId ? WrappedNativeToken[chainId].address : undefined,
    WETH_ABI,
    withSignerIfPossible,
  );
}
