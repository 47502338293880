import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useMemo, useState } from 'react';

import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { useNetworkOptions } from 'hooks/useNetworkOptions';
import { getWalletConnected } from 'hooks/useWalletConnection';
import {
  useCurrentNetwork,
  useCurrentNetworkHandlers,
  useSideMenuHandlers,
} from 'state/application/hooks';
import {
  addNetwork,
  changeCurrentNetwork,
  getMetamaskNetworkParams,
  MetamaskErrorCodes,
} from 'utils/metamask';
import { getNetworkIndex } from 'utils/network';

import { NETWORK_CONFIG } from '../../configs/networks';
import { Select } from '../Select';
import { WalletButton } from '../WalletButton';

import './Header.scss';
import {
  isTransactionRecent,
  newTransactionsFirst,
  useAllTransactions,
} from 'state/transactions/hooks';
import { Loader } from 'components/Loader';

const Header = () => {
  const { library, chainId, active } = useWeb3React();
  const [networkIndex, setNetworkIndex] = useState<number>();
  const networkOptions = useNetworkOptions();
  const currentNetwork = useCurrentNetwork();
  const { setIsSideMenuOpen } = useSideMenuHandlers();
  const { setCurrentNetwork } = useCurrentNetworkHandlers();
  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash);

  const hasPendingTransactions = !!pending.length;

  useEffect(() => {
    const setCurrentNetworkIndex = () => {
      if (getWalletConnected()) {
        const chainId_ = chainId && active ? chainId : currentNetwork?.chainId;
        setNetworkIndex(getNetworkIndex(chainId_, networkOptions));
      } else {
        setNetworkIndex(getNetworkIndex(currentNetwork?.chainId, networkOptions));
      }
    };

    setCurrentNetworkIndex();
  }, [chainId, getWalletConnected(), currentNetwork]);

  async function changeNetwork(option: Network) {
    try {
      if (getWalletConnected()) {
        await changeCurrentNetwork(option.chainId, library.provider);
      }
      setCurrentNetwork(option);
    } catch (err: any) {
      if (err.code === MetamaskErrorCodes.NotNetworkFound) {
        try {
          await addNetwork(getMetamaskNetworkParams(option), library.provider);
        } catch (err) {
          console.error(
            'MetaMask - Add Network: An error ocurred when trying to add a new network.',
            err,
          );
        }
      } else if (err.code === MetamaskErrorCodes.UserRejectedRequest) {
        console.error('MetaMask - User Reject: User Rejection', err);
      }
    }
  }

  return (
    <div className="container-header">
      <div className="d-flex justify-content-end">
        <a className="link-primary d-lg-none mr-auto d-flex justify-content-center align-items-center cursor-pointer">
          <Icon name="menu" size={32} color="ui-main" onClick={() => setIsSideMenuOpen(true)} />
        </a>
        <Select
          className="mx-4"
          optionsList={Object.values(NETWORK_CONFIG)?.map((item: any) => item?.network)}
          displayFields={{
            primary: 'chainName',
            secondary: '',
            icon: 'chainIcon',
          }}
          size="sm"
          selected={networkIndex}
          onSelectOptionChange={option => changeNetwork(option)}
        />
        <WalletButton />
        {hasPendingTransactions && (
          <div className="ml-4 pending-container">
            <Button size="md" type="primary">
              <p className="text-main text-bold mr-3">{pending?.length} Pending</p>
              <Loader style={{ stroke: 'white' }} stroke="white" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
