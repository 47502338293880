import { Currency, Token, MATIC, EWT } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import React, {
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import {
  useAllTokens,
  useToken,
  useIsUserAddedToken,
  useFoundOnInactiveList,
} from '../../../hooks/Tokens';
import { CloseIcon, TYPE } from '../../../theme';
import { isAddress } from '../../../utils';
import Column from '../Column';
import Row, { RowBetween } from '../Row';
import CommonBases from './CommonBases';
import CurrencyList from './CurrencyList';
import { filterTokens, useSortedTokensByQuery } from './filtering';
import { useTokenComparator } from './sorting';
import { PaddedColumn, Separator } from './styleds';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';
import useToggle from 'hooks/useToggle';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import ImportRow from './ImportRow';
import { Edit } from 'react-feather';
import useDebounce from 'hooks/useDebounce';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import './CurrencySearch.scss';
import { Button, Icon } from '@stichting-allianceblock-foundation/components';

interface CurrencySearchProps {
  isOpen: boolean;
  onDismiss: () => void;
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency) => void;
  otherSelectedCurrency?: Currency | null;
  showCommonBases?: boolean;
  showManageView: () => void;
  showImportView: () => void;
  setImportToken: (token: Token) => void;
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  onDismiss,
  isOpen,
  showManageView,
  showImportView,
  setImportToken,
}: CurrencySearchProps) {
  const { t } = useTranslation();
  const { chainId } = useActiveWeb3React();
  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedQuery = useDebounce(searchQuery, 200);

  const [invertSearchOrder] = useState<boolean>(false);

  const allTokens = useAllTokens();

  // if they input an address, use it
  // const isAddressSearch = isAddress(debouncedQuery)
  const searchToken = useToken(debouncedQuery);
  const searchTokenIsAdded = useIsUserAddedToken(searchToken);

  const showETH: boolean = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim();
    return s === '' || s === 'e' || s === 'et' || s === 'eth';
  }, [debouncedQuery]);

  const tokenComparator = useTokenComparator(invertSearchOrder);

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(allTokens), debouncedQuery);
  }, [allTokens, debouncedQuery]);

  const sortedTokens: Token[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator);
  }, [filteredTokens, tokenComparator]);

  const filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery);

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency);
      onDismiss();
    },
    [onDismiss, onCurrencySelect],
  );

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('');
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>();
  const handleInput = useCallback(event => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim();
        if (s === 'matic') {
          handleCurrencySelect(MATIC);
        } else if (s === 'ewt') {
          handleCurrencySelect(EWT);
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0]);
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, debouncedQuery],
  );

  // menu ui
  const [open, toggle] = useToggle(false);
  const node = useRef<HTMLDivElement>();
  useOnClickOutside(node, open ? toggle : undefined);

  // if no results on main list, show option to expand into inactive
  const inactiveTokens = useFoundOnInactiveList(debouncedQuery);
  const filteredInactiveTokens: Token[] = useSortedTokensByQuery(inactiveTokens, debouncedQuery);

  return (
    <div className="currency-search-wrapper">
      <PaddedColumn gap="16px">
        <div className="d-flex justify-content-end">
          <Icon
            className="mr-2"
            color="ui-secondary"
            name="nav-cross"
            size={24}
            onClick={onDismiss}
          />
        </div>
        <RowBetween>
          <div>
            <p className="text-main">
              <Trans i18nKey="addLiquidity:searchTokenText" components={{ span: <span /> }} />
            </p>
          </div>
        </RowBetween>
        <div className="search-field">
          <input
            className="search-input mb-5"
            type="text"
            id="token-search-input"
            placeholder={t('addLiquidity:searchTokenInput')}
            autoComplete="off"
            value={searchQuery}
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput}
            onKeyDown={handleEnter}
          />
          {showCommonBases && (
            <CommonBases
              chainId={chainId}
              onSelect={handleCurrencySelect}
              selectedCurrency={selectedCurrency}
            />
          )}
        </div>
      </PaddedColumn>
      <Separator />
      {searchToken && !searchTokenIsAdded ? (
        <Column style={{ padding: '20px 0', height: '100%' }}>
          <ImportRow
            token={searchToken}
            showImportView={showImportView}
            setImportToken={setImportToken}
          />
        </Column>
      ) : filteredSortedTokens?.length > 0 || filteredInactiveTokens?.length > 0 ? (
        <div style={{ flex: '1' }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <CurrencyList
                height={height}
                showETH={showETH}
                currencies={
                  filteredInactiveTokens
                    ? filteredSortedTokens.concat(filteredInactiveTokens)
                    : filteredSortedTokens
                }
                breakIndex={
                  inactiveTokens && filteredSortedTokens ? filteredSortedTokens.length : undefined
                }
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                selectedCurrency={selectedCurrency}
                fixedListRef={fixedList}
                showImportView={showImportView}
                setImportToken={setImportToken}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <div className="d-flex p-5" style={{ padding: '20px', height: '100%' }}>
          <div className="d-flex">
            <p className="text-main text-bold">No results found.</p>
          </div>
        </div>
      )}
      <div className="footer d-flex justify-content-between">
        <Button size="sm" type="alarm" onClick={onDismiss}>
          <Icon className="mr-2" color="ui-main-background" name="nav-cross" size={24} />
          <span>{t('cancel:cancel')}</span>
        </Button>
        <Button size="sm" type="primary" onClick={showManageView}>
          <Icon className="mr-2" color="ui-main-background" name="staking-new-stake" size={24} />
          <span>{t('addLiquidity:manageToken')}</span>
        </Button>
      </div>
    </div>
  );
}
