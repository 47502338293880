import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AddLiquidityStepper from 'components/AddLiquidityStepper/AddLiquidityStepper';

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  return (
    <>
      <AddLiquidityStepper currencyIdA={currencyIdA} currencyIdB={currencyIdB} history={history} />
    </>
  );
}
