import { useWeb3React } from '@web3-react/core';
import React, { MutableRefObject, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Dropdown, Icon } from '@stichting-allianceblock-foundation/components';
import CoinbaseWalletIcon from 'assets/coinbaseWalletIcon.svg';
import MetamaskIcon from 'assets/metamask.svg';
import WalletConnectIcon from 'assets/walletConnectIcon.svg';
import { AccountDetails } from 'components/AccountDetails';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useDetectOutsideClick } from 'hooks/useDetectClickOutside';
import useENSName from 'hooks/useENSName';
import { useNetworkOptions } from 'hooks/useNetworkOptions';
import { setWalletConnected } from 'hooks/useWalletConnection';
import {
  isTransactionRecent,
  newTransactionsFirst,
  useAllTransactions,
} from 'state/transactions/hooks';
import { getMetamaskProvider } from 'utils/metamask';
import { ConnectorNames, connectorsByName } from 'utils/web3React';

import './WalletButton.scss';
import { InfoTooltip } from 'components/Tooltips';
import { useCurrentNetwork } from 'state/application/hooks';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';

const WalletButton = () => {
  const dropdownRef: MutableRefObject<null | any> = useRef(null);

  const [expand, setExpand] = useDetectOutsideClick(dropdownRef, false);
  const { account, activate, connector, chainId, deactivate } = useWeb3React();
  const { greaterThan } = useBreakpoint();
  const { t } = useTranslation();
  const networkOptions = useNetworkOptions();
  const { ENSName } = useENSName(account ?? undefined);
  const currentNetwork = useCurrentNetwork();

  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash);
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash);

  function handleDisconnect() {
    setWalletConnected(false);
    setExpand(false);
    deactivate();
  }

  async function handleConnectInjected() {
    await activate(connectorsByName[ConnectorNames.Injected]);
    setWalletConnected(true);
    setExpand(!expand);
    const ethereum = getMetamaskProvider();
    ethereum?.removeAllListeners(['networkChanged']);
  }

  function whatIsConnected(activeConnector: any) {
    if (activeConnector === connectorsByName[ConnectorNames.Injected]) {
      return (
        <>
          <div className="d-flex align-items-center">
            <p className="text-bold text-secondary mr-2"> Connected with Metamask</p>
            <InfoTooltip text={'test text'} size={20}></InfoTooltip>
          </div>
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3">
            {/* <span className="text-bold d-flex align-items-center">
              <img src={MetamaskIcon} alt="Metamask Icon" />
              Metamask
            </span> */}
            <Button type="alarm" onClick={() => handleDisconnect()} className="full-md-width">
              <Icon className="mr-2" color="brand-primary" name="nav-login" size={20} />
              <span className="ml-2">{t('walletButton:button.disconnect')}</span>
            </Button>

            <Button type="primary">
              <Icon className="mr-2" color="brand-primary" name="edit-renew" size={20} />
              <span className="ml-2">{t('walletButton:dropdown.clear')}</span>
            </Button>
          </div>
        </>
      );
    } else if (activeConnector === connectorsByName[ConnectorNames.WalletConnect]) {
      return (
        <>
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 mt-4">
            <span className="text-bold d-flex align-items-center">
              <img src={WalletConnectIcon} alt="WalletConnect Icon" />
              WalletConnect
            </span>
            <Button
              disabled={true}
              type="secondary"
              onClick={() => handleDisconnect()}
              className="full-md-width"
            >
              {t('walletButton:button.disconnect')}
            </Button>
          </div>
        </>
      );
    } else if (activeConnector === connectorsByName[ConnectorNames.WalletConnect]) {
      return (
        <>
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 mt-4">
            <span className="text-bold d-flex align-items-center">
              <img src={CoinbaseWalletIcon} alt="CoinbaseWallet Icon" />
              Coinbase Wallet
            </span>
            <Button
              disabled={true}
              type="secondary"
              onClick={() => handleDisconnect()}
              className="full-md-width"
            >
              {t('walletButton:button.disconnect')}
            </Button>
          </div>
        </>
      );
    } else {
      return <div>&nbsp;</div>;
    }
  }

  function accountDetails(account: any) {
    if (account) {
      return (
        <>
          <div className="d-flex align-items-center">
            <p className="text-bold text-secondary mr-2"> Wallet</p>
            <InfoTooltip text={'test text'} size={20}></InfoTooltip>
          </div>
          <div className="d-flex align-items-center mr-2 mt-3 mb-3">
            <div className="d-flex justify-content-sm-start align-items-center mt-4 mt-sm-0">
              <Icon name="swap-rainbow" size={24} />
              <p className="mr-2 ml-2">Wallet Address</p>
            </div>
            <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
            <div className="d-flex justify-content-sm-start align-items-center mt-4 mt-sm-0">
              <div className="line d-flex d-sm-none flex-one  mr-4 mr-sm-0"></div>
              {currentNetwork && (
                <BlockExplorerBadge
                  shorten={true}
                  hash={account}
                  blockExplorer={currentNetwork.blockExplorerUrl}
                  type="address"
                />
              )}
            </div>
          </div>
          <AccountDetails
            pendingTransactions={pending}
            confirmedTransactions={confirmed}
            ENSName={ENSName ?? undefined}
          />
          <hr className="wallet-horizontal-line mt-5 mb-5" />
          {whatIsConnected(connector)}
        </>
      );
    } else {
      return <div>&nbsp;</div>;
    }
  }

  function ifAccountConnected(account: any) {
    if (account) {
      return <></>;
    } else {
      return (
        <>
          <div className="d-flex align-items-center text-main text-bold">
            <Icon name="info-circle" size={24} color="ui-main" />
            <span className="ml-3">{t('walletButton:dropdown.title')}</span>
          </div>
          <div className="text-secondary py-3">
            <Trans i18nKey="walletButton:dropdown.subtitle" components={{ span: <span /> }} />
          </div>
        </>
      );
    }
  }

  function dropDownSelection(account: string, chainId: number) {
    if (networkOptions.map(n => n.chainId).includes(chainId)) {
      return (
        <div className="d-flex align-items-center" ref={dropdownRef}>
          <Dropdown
            expand={expand}
            label={greaterThan('md') ? account : ''}
            icon="wallet"
            onClick={() => setExpand(!expand)}
          >
            {ifAccountConnected(account)}
            {accountDetails(account)}
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className="d-md-flex align-items-center" ref={dropdownRef}>
          <Dropdown
            expand={expand}
            label={greaterThan('md') ? t('walletButton:dropdown.title') : ''}
            alarm
            icon="power-button"
            onClick={() => setExpand(!expand)}
            fullText={true}
          >
            <div className="d-flex align-items-center text-main text-bold">
              <Icon name="info-circle" size={24} color="ui-main" />
              <span className="ml-3">{t('walletButton:dropdown.title')}</span>
            </div>
            <div className="text-secondary py-3">
              <Trans i18nKey="walletButton:dropdown.subtitle" components={{ span: <span /> }} />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 mt-4">
              <span className="text-bold d-flex align-items-center">
                <img className="mr-3" src={MetamaskIcon} alt="Metamask Icon" />
                Metamask
              </span>
              <Button
                type="primary"
                onClick={() => handleConnectInjected()}
                className="full-md-width"
              >
                {t('walletButton:button.connect')}
              </Button>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 mt-4">
              <span className="text-bold d-flex align-items-center">
                <img className="mr-3" src={WalletConnectIcon} alt="WalletConnect Icon" />
                WalletConnect
              </span>
              <Button disabled={true} type="primary" onClick={() => {}} className="full-md-width">
                {t('walletButton:button.connect')}
              </Button>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 mt-4">
              <span className="text-bold d-flex align-items-center">
                <img className="mr-3" src={CoinbaseWalletIcon} alt="CoinbaseWallet Icon" />
                Coinbase Wallet
              </span>
              <Button disabled={true} type="primary" onClick={() => {}} className="full-md-width">
                {t('walletButton:button.connect')}
              </Button>
            </div>
          </Dropdown>
        </div>
      );
    }
  }

  return <>{dropDownSelection(account!, chainId!)}</>;
};

export default WalletButton;
