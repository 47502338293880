import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { parseUnits } from '@ethersproject/units';
import {
  ChainId,
  Currency,
  CurrencyAmount,
  EWT,
  IPool as IPoolInterface,
  JSBI,
  MATIC,
  NativeCurrency,
  Percent,
  Price,
  Token,
  TokenAmount,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { IWallet } from '@stichting-allianceblock-foundation/abdex-sdk-v2/dist/types/i-wallet-dex';
import {
  multiCallUpdateTradePools,
  Trade,
  TradePool,
} from '@stichting-allianceblock-foundation/abdex-trade-sdk';
import {
  BASES_TO_CHECK_TRADES_AGAINST,
  BETTER_TRADE_LESS_HOPS_THRESHOLD,
  CUSTOM_BASES,
  NATIVE_CURRENCY,
} from 'configs/constants';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import flatMap from 'lodash.flatmap';
import { ParsedQs } from 'qs';
import { SerializedToken } from 'state/user/actions';
import { PairState, usePairs } from 'userData/Reserves';
import { isTradeBetter } from 'utils/trades';
import { formatToDecimals, isValidDecimal } from 'utils/userInputFormat';
import { wrappedCurrency, wrappedCurrencyAmount } from 'utils/wrappedCurrency';

import { useCurrency } from '../../hooks/Tokens';
import useENS from '../../hooks/useENS';
import { useUserSingleHopOnly } from '../../state/user/hooks';
import { isAddress } from '../../utils';
import { computeSlippageAdjustedAmounts } from '../../utils/prices';
import { AppDispatch, AppState } from '../index';
import { useUserSlippageTolerance } from '../user/hooks';
import { useCurrencyBalances } from '../wallet/hooks';
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setRecipient,
  switchCurrencies,
  typeInput,
} from './actions';
import { SwapState } from './reducer';
import { bestTradeExactInWorker, bestTradeExactOutWorker } from './routeQueue';

function deserializeToken(serializedToken: SerializedToken): Token {
  return new Token(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name,
  );
}

export function useSwapState(): AppState['swap'] {
  return useSelector<AppState, AppState['swap']>(state => state.swap);
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string, currency: Currency | null | undefined) => void;
  onChangeRecipient: (recipient: string | null) => void;
} {
  const dispatch = useDispatch<AppDispatch>();
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId:
            currency instanceof Token
              ? currency.address
              : currency === MATIC
              ? 'MATIC'
              : currency === EWT
              ? 'EWT'
              : '',
        }),
      );
    },
    [dispatch],
  );

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies());
  }, [dispatch]);

  const onUserInput = useCallback(
    (field: Field, typedValue: string, currency: Currency | null | undefined) => {
      if (currency) {
        if (!isValidDecimal(typedValue, currency)) {
          typedValue = formatToDecimals(typedValue, currency?.decimals);
        }
      }
      dispatch(typeInput({ field, typedValue }));
    },
    [dispatch],
  );

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }));
    },
    [dispatch],
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  };
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : currency === MATIC
        ? NativeCurrency.matic(JSBI.BigInt(typedValueParsed))
        : NativeCurrency.ewt(JSBI.BigInt(typedValueParsed));
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  // necessary for all paths to return a value
  return undefined;
}

// change address
const BAD_RECIPIENT_ADDRESSES: string[] = [
  '0x6c998094dB40FC3295a0Cea34f5ACE795E2fDEC0', // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a', // v2 router 01
  '0xCffFA21fF23CB8CdeBfcFa4D836830DaFa1dd67E', // v2 router 02
];

// /**
//  * Returns true if any of the pairs or tokens in a trade have the given checksummed address
//  * @param trade to check for the given address
//  * @param checksummedAddress address to check in the pairs and tokens
//  */
// function involvesAddress(trade: Trade, checksummedAddress: string): boolean {
//   return (
//     trade.route.path.some(token => token.address === checksummedAddress) ||
//     trade.route.pairs.some(pair => pair.liquidityToken.address === checksummedAddress)
//   );
// }

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(): {
  currencies: { [field in Field]?: Currency };
  currencyBalances: { [field in Field]?: CurrencyAmount };
  parsedAmount: CurrencyAmount | undefined;
  v2Trade: Trade | undefined;
  isFetching: boolean;
  inputError?: string;
} {
  const { account, library, chainId } = useActiveWeb3React();

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = useSwapState();

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);
  const recipientLookup = useENS(recipient ?? undefined);
  const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null;

  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined,
  ]);

  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = tryParseAmount(
    typedValue,
    (isExactIn ? inputCurrency : outputCurrency) ?? undefined,
  );
  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1],
  };

  let inputError: string | undefined;
  const balanceOut: CurrencyAmount | undefined = isExactIn
    ? currencyBalances[Field.INPUT]
    : currencyBalances[Field.OUTPUT];

  if (isExactIn) {
    if (parsedAmount && balanceOut && parsedAmount.greaterThan(balanceOut)) {
      inputError = 'Insufficient ' + balanceOut.currency.symbol + ' balance';
    }
  }

  // const bestTradeExactIn = useTradeExactIn(isExactIn ? parsedAmount : undefined, outputCurrency ?? undefined)
  // const bestTradeExactOut = useTradeExactOut(inputCurrency ?? undefined, !isExactIn ? parsedAmount : undefined)

  const exactInAmountIn = isExactIn ? parsedAmount : undefined;
  const exactInCurrencyOut = outputCurrency ?? undefined;

  const exactOutAmountIn = inputCurrency ?? undefined;
  const exactOutCurrencyOut = !isExactIn ? parsedAmount : undefined;

  const allowedPairsExactIn = useAllCommonPairs(exactInAmountIn?.currency, exactInCurrencyOut);
  const allowedPairsExactOut = useAllCommonPairs(exactOutAmountIn, exactOutCurrencyOut?.currency);

  const [testTradeExactIn, setTestTradeExactIn] = useState<Trade | null>(null);
  const [testTradeExactOut, setTestTradeExactOut] = useState<Trade | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  async function fetchExactIn() {
    if (exactInAmountIn && exactInCurrencyOut && allowedPairsExactIn.length > 0 && !inputError) {
      const tradePools = allowedPairsExactIn.map(pool => new TradePool(pool));

      await multiCallUpdateTradePools(tradePools, library as IWallet);

      const trades = await bestTradeExactInWorker(
        tradePools,
        exactInAmountIn,
        exactInCurrencyOut,
        3,
        1,
      );

      setTestTradeExactIn(trades[0]);
    } else {
      setTestTradeExactIn(null);
    }
  }

  async function fetchExactOut() {
    if (exactOutAmountIn && exactOutCurrencyOut && allowedPairsExactOut.length > 0 && !inputError) {
      const tradePools = allowedPairsExactOut.map(pool => new TradePool(pool));

      await multiCallUpdateTradePools(tradePools, library as IWallet);

      const trades = await bestTradeExactOutWorker(
        tradePools,
        exactOutAmountIn,
        exactOutCurrencyOut,
        3,
        1,
      );

      setTestTradeExactOut(trades[0]);
    } else {
      setTestTradeExactOut(null);
    }
  }

  const fetchExact = async () => {
    if (isExactIn) {
      await fetchExactIn();
    } else {
      await fetchExactOut();
    }
  };

  useEffect(() => {
    setIsFetching(true);
    const id = setTimeout(() => {
      fetchExact().then(() => {
        setIsFetching(false);
      });
    }, 300);
    return () => {
      clearTimeout(id);
    };
  }, [typedValue]);

  useEffect(() => {
    const id = setTimeout(() => {
      fetchExact().then(() => {
        setIsFetching(false);
      });
    }, 500);
    return () => {
      clearTimeout(id);
    };
  }, [allowedPairsExactIn, allowedPairsExactOut]);

  const v2Trade = isExactIn ? testTradeExactIn : testTradeExactOut;

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  };

  // get link to trade on v1, if a better rate exists

  if (!account) {
    inputError = 'Connect Wallet';
  }

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount';
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token';
  }

  const formattedTo = isAddress(to);
  if (!to || !formattedTo) {
    inputError = inputError ?? 'Enter a recipient';
  } else {
    if (
      BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1
      // ||
      // (testTradeExactIn && involvesAddress(testTradeExactIn, formattedTo)) ||
      // (testTradeExactOut && involvesAddress(testTradeExactOut, formattedTo))
    ) {
      inputError = inputError ?? 'Invalid recipient';
    }
  }

  const [allowedSlippage] = useUserSlippageTolerance();

  const slippageAdjustedAmounts =
    v2Trade && allowedSlippage && computeSlippageAdjustedAmounts(v2Trade, allowedSlippage);

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    slippageAdjustedAmounts ? slippageAdjustedAmounts[Field.INPUT] : null,
  ];

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = 'Insufficient ' + amountIn.currency.symbol + ' balance';
  }
  return {
    currencies,
    currencyBalances,
    parsedAmount,
    v2Trade: v2Trade ?? undefined,
    isFetching,
    inputError,
  };
}

function parseCurrencyFromURLParameter(urlParam: any, chainId: ChainId): string | undefined {
  const tokenSymbol = NATIVE_CURRENCY[chainId].symbol;
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    if (urlParam.toUpperCase() === tokenSymbol) return tokenSymbol;
    if (valid === false) return tokenSymbol;
  }
  return tokenSymbol ?? '';
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : '';
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output'
    ? Field.OUTPUT
    : Field.INPUT;
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/;
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null;
  const address = isAddress(recipient);
  if (address) return address;
  if (ENS_NAME_REGEX.test(recipient)) return recipient;
  if (ADDRESS_REGEX.test(recipient)) return recipient;
  return null;
}

export function queryParametersToSwapState(parsedQs: ParsedQs, chainId: any): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency, chainId);
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency, chainId);
  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = '';
    } else {
      outputCurrency = '';
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient);

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency,
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient,
  };
}

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): IPoolInterface[] {
  const { chainId } = useActiveWeb3React();

  const bases: Token[] = chainId ? BASES_TO_CHECK_TRADES_AGAINST[chainId] : [];

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined];

  const basePairs: [Token, Token][] = useMemo(
    () =>
      flatMap(bases, (base): [Token, Token][] => bases.map(otherBase => [base, otherBase])).filter(
        ([t0, t1]) => t0.address !== t1.address,
      ),
    [bases],
  );

  const tokensFromLocalStorage = useSelector<AppState, AppState['user']['tokens']>(state => {
    return state.user.tokens;
  });

  const tokensArrayFromStorage: Token[] = useMemo(() => {
    if (!chainId || !tokensFromLocalStorage) return [];
    const forChain = tokensFromLocalStorage[chainId];
    if (!forChain) return [];

    return Object.keys(forChain).map((pairId, index) => {
      return deserializeToken(forChain[pairId]);
    });
  }, [tokensFromLocalStorage, chainId]);

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // token A against all bases
            ...tokensArrayFromStorage.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...tokensArrayFromStorage.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs,
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
            .filter(([tokenA, tokenB]) => {
              if (!chainId) return true;
              const customBases = CUSTOM_BASES[chainId];
              if (!customBases) return true;

              const customBasesA: Token[] | undefined = customBases[tokenA.address];
              const customBasesB: Token[] | undefined = customBases[tokenB.address];

              if (!customBasesA && !customBasesB) return true;

              if (customBasesA && !customBasesA.find(base => tokenB.equals(base))) return false;
              if (customBasesB && !customBasesB.find(base => tokenA.equals(base))) return false;

              return true;
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId],
  );

  const allPairs = usePairs(allPairCombinations);

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, IPoolInterface] =>
            Boolean(result[0] === PairState.EXISTS && result[1]),
          )
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: IPoolInterface }>((memo, [, curr]) => {
            memo[curr.address] = memo[curr.address] ?? curr;
            return memo;
          }, {}),
      ),
    [allPairs],
  );
}
