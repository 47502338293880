import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IPool } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { ReactComponent as EmptyPoolsSvgDesktop } from 'assets/add-liquidity-desktop.svg';
import { ReactComponent as EmptyPoolsSvgMobile } from 'assets/add-liquidity-desktop.svg';
import Counter from 'components/Counter/Counter';
import { Filter } from 'components/Filter';
import { PoolCard } from 'components/PoolCard';
import { Search } from 'components/Search';
import { Title } from 'components/Title';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks';
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks';
import { usePairs } from 'userData/Reserves';

import './Pools.scss';

export default function Pools() {
  const { t } = useTranslation();
  const { greaterThan } = useBreakpoint();

  const { account } = useActiveWeb3React();

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs],
  );

  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens],
  );

  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens,
  );
  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0'),
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances],
  );

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens));

  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some(V2Pair => !V2Pair);

  const allV2PairsWithLiquidity = v2Pairs
    .map(([, pair]) => pair)
    .filter((v2Pair): v2Pair is IPool => Boolean(v2Pair));

  return (
    <div className="p-6 mt-8 mb-5 my-md-7">
      <Title title={t('pools:poolsTitle')} description={t('pools:poolsDescription')}></Title>
      <div className="flex-row filter-controls d-flex justify-content-between">
        <div className="d-flex">
          <Filter />
          <Search />
        </div>
        <div className="d-flex align-items-center">
          <Counter count={allV2PairsWithLiquidity.length} />
        </div>
      </div>
      {allV2PairsWithLiquidity?.length > 0 ? (
        <div className="pools-wrapper">
          <div className="my-2 ml-5 mr-5 pools-header d-flex">
            <div className="ml-3 mr-16 d-flex">
              <p className="text-tiny text-bold text-border">Tokens</p>
            </div>
            <div className="flex-1 d-flex">
              <p className="text-tiny text-bold text-border">Name &amp; ticker</p>
            </div>
            <div className="mr-16 d-flex">
              <p className="text-tiny text-bold text-border">Amount</p>
            </div>
            <div className="mr-5 d-flex">
              <p className="text-tiny text-bold text-border">Actions</p>
            </div>
          </div>
          <div className="p-5 pools-container">
            {allV2PairsWithLiquidity.map(v2Pair => (
              <PoolCard key={v2Pair.address} pool={v2Pair} />
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="svg-wrapper-100 d-flex justify-content-center">
            {greaterThan('md') ? (
              <EmptyPoolsSvgDesktop className="max-width-100" />
            ) : (
              <EmptyPoolsSvgMobile className="max-width-100" />
            )}
          </div>
        </>
      )}
    </div>
  );
}
