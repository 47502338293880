import { Currency } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { parseUnits } from '@ethersproject/units';

// check if user is trying to add more than 18 zeros
export const isValidDecimal = (valueStr: string, currency: Currency | null | undefined) => {
  try {
    if (currency?.decimals) {
      parseUnits(valueStr, currency?.decimals);
      return true;
    } else {
      return true;
    }
  } catch {
    if (valueStr === '') {
      return true;
    }
    window.alert(`User input has more than ${currency?.decimals} decimals!`);
    return false;
  }
};

export const formatToDecimals = (toFormat: string, decimals: number | null | undefined) => {
  const formatArr = toFormat.split('.');
  const splicedDecimals = formatArr[1]
    .split('')
    .splice(0, decimals ? decimals + 1 : 19)
    .join('');
  return [formatArr[0], splicedDecimals].join('.');
};
