import { useWeb3React } from '@web3-react/core';
import React, { useState } from 'react';

import { Step, Stepper } from '@stichting-allianceblock-foundation/components';

import './SwapStepper.scss';
import SwapAssets from './Steps/SwapAssets';

interface SwapStepperProps {
  currencyIdA?: string;
  currencyIdB?: string;
  history: any;
}

const SwapStepper = ({ currencyIdA, currencyIdB, history }: SwapStepperProps) => {
  const { account, chainId, library } = useWeb3React();
  const [activeStep, setActiveStep] = useState(0);

  return (
    <div className="swap-stepper my-4">
      <Stepper>
        <Step activeStep={activeStep} stepNumber={0} subtitle="" title="">
          <SwapAssets currencyIdA={currencyIdA} currencyIdB={currencyIdB} history={history} />
        </Step>
      </Stepper>
    </div>
  );
};

export default SwapStepper;
