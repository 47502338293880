import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Currency,
  CurrencyAmount,
  IPool,
  JSBI,
  Percent,
  Token,
  TokenAmount,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { Icon } from '@stichting-allianceblock-foundation/components';
import CurrencyLogo from 'components/StyledComponents/CurrencyLogo';
import { InfoTooltip } from 'components/Tooltips';
import { ONE_BIPS } from 'configs/constants';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { useCurrentNetwork } from 'state/application/hooks';
import { Field } from 'state/burn/actions';
import { useTokenBalance } from 'state/wallet/hooks';
import { useTotalSupply } from 'userData/TotalSupply';

import './RemoveLiquidityTransactionInfo.scss';

interface RemoveLiquidityTransactionInfo {
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
  liquidityToken: Token | undefined;
  pool: IPool | null | undefined;
  parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: Percent;
    [Field.LIQUIDITY]?: TokenAmount;
    [Field.CURRENCY_A]?: CurrencyAmount;
    [Field.CURRENCY_B]?: CurrencyAmount;
  };
}

const RemoveLiquidityTransactionInfo = ({
  currencyA,
  currencyB,
  liquidityToken,
  pool,
  parsedAmounts,
}: RemoveLiquidityTransactionInfo) => {
  const { t } = useTranslation();
  const currentNetwork = useCurrentNetwork();
  const { account } = useActiveWeb3React();

  const userPoolBalance = useTokenBalance(account ?? undefined, liquidityToken);
  const totalPoolTokens = useTotalSupply(liquidityToken);

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pool &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pool.getLiquidityValue(pool.reserve0(), totalPoolTokens, userPoolBalance),
          pool.getLiquidityValue(pool.reserve1(), totalPoolTokens, userPoolBalance),
        ]
      : [undefined, undefined];

  return (
    <div className="transactions-wrapper">
      <div className="mb-5 transaction-info">
        <div className="d-flex">
          <p className="text-small text-secondary text-bold">Pooled Tokens</p>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <p className="mr-2">1</p>
            <Icon className="mr-4" color="ui-secondary" name="staking-new-stake" size={24} />
            <p className="ml-2 text-secondary">From</p>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <p className="mr-2">{token0Deposited?.toSignificant(6) ?? '0.00'}</p>
            <CurrencyLogo currency={currencyA} size={'18px'} />
            <p className="ml-2 text-secondary">{currencyA?.symbol}</p>
          </div>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <p className="mr-2">1</p>
            <Icon className="mr-4" color="ui-secondary" name="staking-new-stake" size={24} />
            <p className="ml-2 text-secondary">To</p>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <p className="mr-2">{token1Deposited?.toSignificant(6) ?? '0.00'}</p>
            <CurrencyLogo currency={currencyB} size={'18px'} />
            <p className="ml-2 text-secondary">{currencyB?.symbol}</p>
          </div>
        </div>
      </div>

      <div className="mb-5 transaction-info">
        <div className="d-flex">
          <p className="text-small text-secondary text-bold">Pool</p>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">Share of pool</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <div className="d-flex flex-column flex-md-row">
              <p className="ml-2 text-bold">{poolTokenPercentage?.toSignificant(4) ?? '0'}%</p>
            </div>
          </div>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">You will burn</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <p className="mr-4 text-main text-bold">
              {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
            </p>
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex">
                {currentNetwork && <Icon name="coin-albt" size={18} className="mr-2" />}
                <p className="text-uppercase text-secondary">ABLP</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5 transaction-info">
        <div className="d-flex">
          <p className="text-small text-secondary text-bold">Fees</p>
        </div>
        {/* <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">Network Fees</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex">
                {currentNetwork && (
                  <Icon name={currentNetwork?.chainIcon} size={18} className="mr-2" />
                )}
                <p className="text-uppercase text-secondary">
                  {currentNetwork?.nativeCurrency?.symbol}
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RemoveLiquidityTransactionInfo;
