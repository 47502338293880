import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { useIsSideMenuOpen, useSideMenuHandlers } from 'state/application/hooks';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Accordion } from '../Accordion';

import './SideMenu.scss';

enum SideMenuSection {
  test,
}

const SideMenu = ({ logo, title, subtitle }: SideMenuProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { chainId, library } = useWeb3React();
  const { lessThan } = useBreakpoint();
  const { setIsSideMenuOpen } = useSideMenuHandlers();
  const isSideMenuOpen = useIsSideMenuOpen();
  const accordionItemsRef = useRef(null);

  const RouteToSideMenuSection: { [route: string]: SideMenuSection } = {
    '/': SideMenuSection.test,
  };

  const [activeSection, setActiveSection] = useState<SideMenuSection>(() => {
    const pathname = location.pathname;
    const base = pathname.split('/')[1] ? `/${pathname.split('/')[1]}` : '/';
    return RouteToSideMenuSection[base];
  });

  const handleToggle = (e: React.MouseEvent, section: SideMenuSection) => {
    setActiveSection(section);

    const list = document.querySelectorAll('.accordion-item');

    for (const item in list) {
      if (typeof list[item] === 'object') {
        if (list[item].classList.contains('active')) {
          list[item].classList.remove('active');
        }
      }
    }
    e.currentTarget.classList.add('active');
    if (lessThan('lg')) {
      setIsSideMenuOpen(false);
    }
  };

  useEffect(() => {
    const loadSigner = async () => {
      if (library) {
        try {
          // setSigner
        } catch (error) {
          console.log('error catched');
        }
      }
    };

    loadSigner();
  }, [library]);

  return (
    <div className={`sidemenu-container ${isSideMenuOpen ? 'sidemenu-opened' : ''} p-8`}>
      <div className="sidemenu-content" ref={accordionItemsRef}>
        {isSideMenuOpen && (
          <div className="d-flex justify-content-end mb-5">
            <Icon
              name="cross-big"
              size={32}
              color="ui-main"
              onClick={() => setIsSideMenuOpen(false)}
            />
          </div>
        )}
        <div className="d-flex flex-column">
          <NavLink
            className="text-decoration-none mb-5"
            to={{
              pathname: '/',
              state: { prevPath: location.pathname },
            }}
          >
            <div className="d-flex align-items-center">
              <img height={44} src={logo} alt={`${title} Logo`} />
              <div className="ml-4">
                <div className="text-secondary text-tiny">{title}</div>
                <div className="text-solid text-bold text-normal sidemenu-subtitle">{subtitle}</div>
              </div>
            </div>
          </NavLink>
          <div className="mt-6">
            <div className="mb-4">
              <Accordion
                title={t('sideMenu:accordionDex.title')}
                expanded={activeSection === SideMenuSection.test}
              >
                <li>
                  <NavLink
                    className="accordion-item"
                    onClick={e => handleToggle(e, SideMenuSection.test)}
                    to={{
                      pathname: '/swap',
                      state: { prevPath: location.pathname },
                    }}
                    exact
                  >
                    <span className="d-flex align-items-center">
                      <Icon name="staking-swap" size={20} color="ui-secondary" />
                      <span className="ml-3 text-bold">{t('sideMenu:accordionDex.transfer')}</span>
                      <span className="quantity-number text-bold"></span>
                      <Icon className="ml-3" name="chevron-right" size={16} color="ui-secondary" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="accordion-item"
                    onClick={e => handleToggle(e, SideMenuSection.test)}
                    to={{
                      pathname: '/add',
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <Icon name="edit-plus" size={20} color="ui-secondary" />
                      <span className="ml-3 text-bold">
                        {t('sideMenu:accordionDex.addLiquidity')}
                      </span>
                      <span className="quantity-number text-bold"></span>
                      <Icon className="ml-3" name="chevron-right" size={16} color="ui-secondary" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="accordion-item"
                    onClick={e => handleToggle(e, SideMenuSection.test)}
                    to={{
                      pathname: '/pools',
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <Icon name="staking-pool" size={20} color="ui-secondary" />
                      <span className="ml-3 text-bold">{t('sideMenu:accordionDex.myPools')}</span>
                      <span className="quantity-number text-bold"></span>
                      <Icon className="ml-3" name="chevron-right" size={16} color="ui-secondary" />
                    </span>
                  </NavLink>
                </li>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
