import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/Tooltips';

interface TitleProps {
  title: string;
  description: string;
  tooltip?: string;
}

const Title = ({ title, description, tooltip }: TitleProps) => {
  const { t } = useTranslation();
  return (
    <div className="mb-5">
      <div className="d-flex align-items-center">
        <div className="mb-3 mr-3 text-medium text-bold text-main">
          <Trans i18nKey={title} components={{ span: <span /> }} />
        </div>
        {tooltip && <InfoTooltip text={tooltip} size={20} />}
      </div>
      <div className="text-main">
        <Trans i18nKey={description} components={{ span: <span /> }} />
      </div>
    </div>
  );
};

export default Title;
