import { useMemo } from 'react';

import { TransactionResponse } from '@ethersproject/providers';
import {
  Currency,
  currencyEquals,
  EWT,
  MATIC,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { WrappedNativeToken } from 'configs/constants';

import { tryParseAmount } from '../state/swap/hooks';
import { useTransactionAdder } from '../state/transactions/hooks';
import { useCurrencyBalance } from '../state/wallet/hooks';
import { useActiveWeb3React } from './useActiveWeb3React';
import { useWETHContract } from './useContract';

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE };
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  typedValue: string | undefined,
): {
  wrapType: WrapType;
  execute?: undefined | (() => Promise<TransactionResponse>);
  inputError?: string;
} {
  const { chainId, account } = useActiveWeb3React();
  const wethContract = useWETHContract();
  const balance = useCurrencyBalance(account ?? undefined, inputCurrency);
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency), [
    inputCurrency,
    typedValue,
  ]);
  const addTransaction = useTransactionAdder();

  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE;

    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount);

    if (
      (inputCurrency === MATIC || inputCurrency === EWT) &&
      currencyEquals(WrappedNativeToken[chainId], outputCurrency)
    ) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                const txReceipt = await wethContract.deposit({
                  value: `0x${inputAmount.raw.toString(16)}`,
                });

                if (inputCurrency === MATIC) {
                  addTransaction(txReceipt, {
                    // summary: `Wrap ${inputAmount.toSignificant(6)} MATIC to WMATIC`,
                    summary: {
                      action: 'Wrap',
                      details: {
                        amount0: inputAmount.toSignificant(6),
                        currency0: MATIC,
                        currency1: WrappedNativeToken[chainId],
                      },
                    },
                  });
                }

                if (inputCurrency === EWT) {
                  addTransaction(txReceipt, {
                    // summary: `Wrap ${inputAmount.toSignificant(6)} EWT to WEWT`,
                    summary: {
                      action: 'Wrap',
                      details: {
                        amount0: inputAmount.toSignificant(6),
                        currency0: EWT,
                        currency1: WrappedNativeToken[chainId],
                      },
                    },
                  });
                }

                return txReceipt;
              }
            : undefined,
        inputError: sufficientBalance ? undefined : `Insufficient ${inputCurrency.symbol} balance`,
      };
    } else if (
      currencyEquals(WrappedNativeToken[chainId], inputCurrency) &&
      (outputCurrency === MATIC || outputCurrency === EWT)
    ) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                const txReceipt = await wethContract.withdraw(`0x${inputAmount.raw.toString(16)}`);
                if (outputCurrency === MATIC) {
                  addTransaction(txReceipt, {
                    // summary: `Unwrap ${inputAmount.toSignificant(6)} WMATIC to MATIC`,
                    summary: {
                      action: 'Unwrap',
                      details: {
                        amount0: inputAmount.toSignificant(6),
                        currency0: WrappedNativeToken[chainId],
                        currency1: MATIC,
                      },
                    },
                  });
                }
                if (outputCurrency === EWT) {
                  addTransaction(txReceipt, {
                    // summary: `Unwrap ${inputAmount.toSignificant(6)} WEWT to EWT`,
                    summary: {
                      action: 'Unwrap',
                      details: {
                        amount0: inputAmount.toSignificant(6),
                        currency0: WrappedNativeToken[chainId],
                        currency1: EWT,
                      },
                    },
                  });
                }

                return txReceipt;
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : `Insufficient W${outputCurrency.symbol} balance`,
      };
    } else {
      return NOT_APPLICABLE;
    }
  }, [wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction]);
}
