import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Currency } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { Trade, TradeType } from '@stichting-allianceblock-foundation/abdex-trade-sdk';
import { Icon } from '@stichting-allianceblock-foundation/components';
import CurrencyLogo from 'components/StyledComponents/CurrencyLogo';
import { InfoTooltip } from 'components/Tooltips';
import { ONE_BIPS } from 'configs/constants';
import { useCurrentNetwork } from 'state/application/hooks';
import { Field } from 'state/swap/actions';
import { useUserSlippageTolerance } from 'state/user/hooks';
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
} from 'utils/prices';

import './SwapTransactionInfo.scss';

interface SwapTransactionInfo {
  currencies: { [field in Field]?: Currency };
  tokenOutputFiatPrice: number;
  trade?: Trade;
}

const SwapTransactionInfo = ({ currencies, tokenOutputFiatPrice, trade }: SwapTransactionInfo) => {
  const { t } = useTranslation();
  const currentNetwork = useCurrentNetwork();

  const showInverted = false;
  const price = formatExecutionPrice(trade, showInverted);

  const [allowedSlippage] = useUserSlippageTolerance();

  const slippageAdjustedAmounts =
    trade && allowedSlippage && computeSlippageAdjustedAmounts(trade, allowedSlippage);

  const { priceImpactWithoutFee, realizedLPFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade],
  );

  return (
    <div className="transactions-wrapper">
      <div className="mb-5 transaction-info">
        <div className="d-flex">
          <p className="text-small text-secondary text-bold">Ratio</p>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <p className="mr-2">1</p>
            <CurrencyLogo currency={currencies[Field.INPUT]} size={'18px'} />
            <p className="ml-2 text-secondary">{currencies[Field.INPUT]?.symbol}</p>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <p className="mr-2">{trade?.executionPrice.toSignificant(6) ?? '0.00'}</p>
            <CurrencyLogo currency={currencies[Field.OUTPUT]} size={'18px'} />
            <p className="ml-2 text-secondary">{currencies[Field.OUTPUT]?.symbol}</p>
          </div>
        </div>
        <div className="mt-4 d-flex">
          <p className="text-small text-secondary text-bold">Transaction details</p>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="price-impact" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">Price Impact</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <p className="mr-4 text-main text-bold">
              {' '}
              {trade?.priceImpact.lessThan(ONE_BIPS)
                ? '<0.01'
                : trade?.priceImpact.toSignificant(4)}
              %
            </p>
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
          </div>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="allowed-slippage" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">Allowed Slippage</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <p className="mr-4 text-main text-bold"> {allowedSlippage / 100}%</p>
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
          </div>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="edit-download" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">
                {trade?.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
              </p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            {slippageAdjustedAmounts && (
              <p className="mr-2 text-main text-bold">
                {trade?.tradeType === TradeType.EXACT_INPUT
                  ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                  : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
              </p>
            )}
            {trade && (
              <CurrencyLogo
                currency={
                  trade.tradeType === TradeType.EXACT_INPUT
                    ? currencies[Field.OUTPUT]
                    : currencies[Field.INPUT]
                }
                size={'18px'}
              />
            )}
            {trade && (
              <p className="ml-2 text-secondary">
                {trade.tradeType === TradeType.EXACT_INPUT
                  ? trade.outputAmount.currency.symbol
                  : trade.inputAmount.currency.symbol}
              </p>
            )}

            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
          </div>
        </div>
      </div>

      <div className="mb-5 transaction-info">
        <div className="d-flex">
          <p className="text-small text-secondary text-bold">Fees</p>
        </div>
        {/* <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">Network Fees</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex">
                {currentNetwork && (
                  <Icon name={currentNetwork?.chainIcon} size={18} className="mr-2" />
                )}
                <p className="text-uppercase text-secondary">
                  {currentNetwork?.nativeCurrency?.symbol}
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="staking-pool" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">Liquidity provider fees</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            {trade && (
              <>
                <p className="ml-2 mr-2 text-main text-bold">
                  {realizedLPFee ? realizedLPFee?.toSignificant(12) : '-'}
                </p>
                <CurrencyLogo currency={trade.inputAmount.currency} size={'18px'} />
                <p className="ml-2 mr-2 text-secondary">{trade.inputAmount.currency.symbol}</p>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="mb-5">
        <div className="d-flex">
          <p className="text-small text-secondary text-bold">Route</p>
        </div>
        <p>
          This route optimizes your price by considering split routes, multiple hops and gas costs.
        </p>
        <div className="mr-2 d-flex align-items-center">
          {trade?.route.path.map((token, key) => {
            return (
              <>
                <CurrencyLogo currency={token} size={'18px'} />
                <p className="ml-2 mr-2 text-main text-bold">{token.symbol}</p>
                <p className="ml-2 mr-2 text-secondary">{token.name}</p>
                {trade.route.path.length - 1 !== key && (
                  <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SwapTransactionInfo;
