import React from 'react';

import { Icon } from '@stichting-allianceblock-foundation/components';
import { Loader } from 'components/Loader';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { getEtherscanLink } from 'utils';

import { Summary, useAllTransactions } from '../../state/transactions/hooks';

import './Transaction.scss';
import CurrencyLogo from 'components/StyledComponents/CurrencyLogo';
import { Currency, Token } from '@stichting-allianceblock-foundation/abdex-sdk-v2';

export default function Transaction({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React();
  const allTransactions = useAllTransactions();

  const tx = allTransactions?.[hash];
  const summary = tx?.summary;
  const pending = !tx?.receipt;

  const success =
    !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined');

  if (!chainId) return null;

  return (
    <div className="transaction-wrapper">
      <div className="transaction-state d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {pending ? (
            <Loader size={'18px'} style={{ marginRight: '5px' }} />
          ) : (
            <Icon
              className="mr-2"
              color={!pending && !success ? 'attention-alarm' : 'ui-main'}
              name={
                summary?.action?.includes('Approve')
                  ? 'nav-ok'
                  : summary?.action?.includes('Add')
                  ? 'edit-plus'
                  : summary?.action?.includes('Swap')
                  ? 'staking-swap'
                  : summary?.action?.includes('Withdraw')
                  ? 'staking-withdraw'
                  : 'edit-info'
              }
              size={18}
            />
          )}
          <p className={`text-normal ${!pending && !success ? 'warning' : ''}`}>
            {summary?.action}
          </p>
        </div>
        <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>

        <div className="d-flex align-items-center mr-2">
          <div className="d-flex justify-content-sm-start align-items-center mt-4 mt-sm-0">
            {summary?.details?.amount0 && (
              <p className="mr-2 text-normal text-bold">{summary?.details?.amount0}</p>
            )}
            {summary?.details?.currency0 && (
              <CurrencyLogo currency={summary?.details?.currency0 as Token} size={'18px'} />
            )}
            {summary?.details?.currency0?.symbol && (
              <p className="text-normal text-secondary ml-2">
                {summary?.details?.currency0?.symbol}
              </p>
            )}
          </div>
          <Icon size={18} name="arrow-right-long" color="ui-border" className="ml-3 mr-3" />
          <div className="d-flex justify-content-sm-start align-items-center mt-4 mt-sm-0">
            {summary?.details?.amount1 && (
              <p className="mr-2 text-normal text-bold">{summary?.details?.amount1}</p>
            )}
            {summary?.details?.currency1 && (
              <CurrencyLogo currency={summary?.details?.currency1 as Token} size={'18px'} />
            )}
            {summary?.details?.currency1?.symbol && (
              <p className="text-normal text-secondary ml-2">
                {summary?.details?.currency1?.symbol}
              </p>
            )}
          </div>
        </div>

        <div className="d-flex">
          <a
            className="d-flex align-items-center ml-1"
            href={getEtherscanLink(chainId, hash, 'transaction')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {success || pending ? (
              <Icon size={18} name="link-external-link" color="ui-border" />
            ) : (
              <Icon size={18} name="nav-attention" color="attention-alarm" />
            )}
          </a>
        </div>
      </div>
    </div>
  );
}
