import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { nanoid } from '@reduxjs/toolkit';
import { TokenList } from 'configs/tokenInterfaces';
import getTokenList from 'utils/getTokenList';

import { AppDispatch } from '../state';
import { fetchTokenList } from '../state/lists/actions';
import { resolveENSContentHash } from '../utils/resolveENSContentHash';
import { useActiveWeb3React } from './useActiveWeb3React';

export function useFetchListCallback(): (
  listUrl: string,
  sendDispatch?: boolean,
) => Promise<TokenList> {
  const { chainId, library } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();

  const ensResolver = useCallback(
    (ensName: string) => {
      // if (!library || chainId !== ChainId.POLYGON) {
      //   if (chainId === ChainId.POLYGON) {
      //     const networkLibrary = getNetworkLibrary()
      //     if (networkLibrary) {
      //       return resolveENSContentHash(ensName, networkLibrary)
      //     }
      //   }
      //   throw new Error('Could not construct mainnet ENS resolver')
      // }
      if (!library) {
        throw new Error('Could not construct mainnet ENS resolver');
      }
      return resolveENSContentHash(ensName, library);
    },
    [chainId, library],
  );

  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(
    async (listUrl: string, sendDispatch = true) => {
      const requestId = nanoid();
      sendDispatch && dispatch(fetchTokenList.pending({ requestId, url: listUrl }));
      return getTokenList(listUrl, ensResolver)
        .then(tokenList => {
          sendDispatch &&
            dispatch(fetchTokenList.fulfilled({ url: listUrl, tokenList, requestId }));
          return tokenList;
        })
        .catch(error => {
          console.debug(`Failed to get list at url ${listUrl}`, error);
          sendDispatch &&
            dispatch(
              fetchTokenList.rejected({ url: listUrl, requestId, errorMessage: error.message }),
            );
          throw error;
        });
    },
    [dispatch, ensResolver],
  );
}
