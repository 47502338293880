import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@stichting-allianceblock-foundation/components';
import './Attention.scss';
interface AttentionProps {
  title: string;
  description: string;
  color?: boolean;
}

function Attention({ title, description, color }: AttentionProps) {
  const { t } = useTranslation();

  return (
    <div className="d-flex mx-4 mt-7 mb-5">
      <Icon
        name="info-circle"
        size={20}
        color={color ? 'attention-alarm' : 'ui-main'}
        className="mr-4"
      />
      <div>
        <p className={`text-bold ${color ? 'red' : ''}`}>
          <Trans i18nKey={title} components={{ span: <span /> }} />
        </p>
        <p className={`text-align-justify ${color ? 'red' : ''}`}>
          <Trans i18nKey={description} components={{ span: <span /> }} />
        </p>
      </div>
    </div>
  );
}

export default Attention;
