import React, { useState } from 'react';

import './Search.scss';
import { useTranslation } from 'react-i18next';
import { TextField } from '@stichting-allianceblock-foundation/components';

export default function Search() {
  const { t } = useTranslation();
  const [searchedWord, setSearchedWord] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleTokenIdInputChange = async (value: string) => {
    setSearchedWord(value);
  };
  return (
    <div className="d-flex align-items-center">
      <TextField
        className="collection-address"
        placeholder="Search token"
        icon="nav-search"
        value={searchedWord}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleTokenIdInputChange(event.target.value)
        }
        error={error}
      />
    </div>
  );
}
