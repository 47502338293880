import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';

import { JsonRpcSigner } from '@ethersproject/providers';
import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { ReactComponent as ConnectWalletDesktopLogo } from 'assets/connect-wallet-desktop.svg';
import { ReactComponent as ConnectWalletMobileLogo } from 'assets/connect-wallet-mobile.svg';
import { useEagerConnect } from 'hooks/useEagerConnect';
import { useInactiveListener } from 'hooks/useInactiveListener';
import { setWalletConnected } from 'hooks/useWalletConnection';
import AddLiquidity from 'pages/AddLiquidity';
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectPathSwapOnly,
  RedirectToAddLiquidity,
} from 'pages/AddLiquidity/redirects';
import MyPools from 'pages/MyPools';
import RemoveLiquidity from 'pages/RemoveLiquidity';
import { RedirectOldRemoveLiquidityPathStructure } from 'pages/RemoveLiquidity/redirects';
import Swap from 'pages/Swap';
import { RedirectToSwap } from 'pages/Swap/redirects';
import { useCurrentNetwork, useSideMenuHandlers } from 'state/application/hooks';
import {
  addNetwork,
  changeCurrentNetwork,
  getMetamaskNetworkParams,
  getMetamaskProvider,
  MetamaskErrorCodes,
} from 'utils/metamask';
import { ConnectorNames, connectorsByName } from 'utils/web3React';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Header } from '../Header';
import { SideMenu } from '../SideMenu';

import './Layout.scss';

const Layout = () => {
  const {
    connector,
    library,
    chainId,
    deactivate,
    activate,
    active,
    account,
    error,
  } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState();
  const { width, greaterThan } = useBreakpoint();
  const { t } = useTranslation();
  const [signer, setSigner] = useState<JsonRpcSigner>();
  const currentNetwork = useCurrentNetwork();
  const { setIsSideMenuOpen } = useSideMenuHandlers();

  const config = {
    brandColor: '',
    theme: 'light',
    companyName: 'AllianceBlock',
    companyLogoUrl: '/assets/img/allianceblock.svg',
    productName: 'DEX',
  };

  useEffect(() => {
    const loadSigner = async () => {
      if (library) {
        try {
          setSigner(library.getSigner());
        } catch (error) {
          console.log('error catched');
        }
      }
    };

    loadSigner();
  }, [library]);

  useEffect(() => {
    // So, we make sure that the side menu always is close when meets lg breakpoint
    if (greaterThan('lg')) {
      setIsSideMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useEagerConnect();

  useInactiveListener(!triedEager || !!activatingConnector);

  function handleDisconnect() {
    setWalletConnected(false);
    deactivate();
  }

  async function handleConnectInjected() {
    await activate(connectorsByName[ConnectorNames.Injected]);
    setWalletConnected(true);
    const ethereum = getMetamaskProvider();
    ethereum?.removeAllListeners(['networkChanged']);
  }

  useEffect(() => {
    const changeNetworkIfNotTheSame = async () => {};

    changeNetworkIfNotTheSame();
  }, []);

  useEffect(() => {
    const changeNetworkIfNotSupported = async () => {
      if (error instanceof UnsupportedChainIdError) {
        const provider = getMetamaskProvider();
        try {
          await changeCurrentNetwork(currentNetwork?.chainId, provider);
        } catch (err) {
          if ((err as any).code === MetamaskErrorCodes.NotNetworkFound) {
            try {
              await addNetwork(getMetamaskNetworkParams(currentNetwork), provider);
            } catch (err) {
              console.error(
                'MetaMask - Add Network: An error ocurred when trying to add a new network.',
                err,
              );
            }
          } else {
            console.error(err);
          }
        }
      }
    };

    changeNetworkIfNotSupported();
  }, [error]);

  const renderNotConnected = () => {
    return (
      <div className="mt-8 mb-5 my-md-7">
        <div className="mb-3 mr-5 text-medium text-bold text-main">
          {t('layout:notConnected.title')}
        </div>

        <div className="text-main">
          <Trans i18nKey="layout:notConnected.subtitle" components={{ span: <span /> }} />
        </div>

        <div className="mt-4 svg-wrapper-100 d-flex justify-content-center">
          {greaterThan('md') ? (
            <ConnectWalletDesktopLogo className="max-width-100" />
          ) : (
            <ConnectWalletMobileLogo className="max-width-100" />
          )}
        </div>

        <div className="mt-4 mb-5">
          <div className="mb-3 text-center text-medium text-bold text-main">
            {t('layout:notConnected.welcome', {
              productName: config.productName,
            })}
          </div>
          <div className="text-center">
            <Trans
              i18nKey="layout:notConnected.welcomeDescription"
              components={{ span: <span /> }}
              values={{ productName: config.productName }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button type="primary" onClick={() => handleConnectInjected()}>
            <Icon color="uiElementSecondary" size={20} name="wallet" className="mr-3" />
            <span>{t('walletButton:button.connectWallet')}</span>
          </Button>
        </div>
      </div>
    );
  };

  const renderConnected = () => {
    return (
      // TODO: Render Connected Content
      <Switch>
        <Route exact strict path="/create" component={RedirectToAddLiquidity} />
        <Route exact path="/add" component={AddLiquidity} />
        <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
        <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
        <Route exact path="/create" component={AddLiquidity} />
        <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
        <Route
          exact
          path="/create/:currencyIdA/:currencyIdB"
          component={RedirectDuplicateTokenIds}
        />
        <Route exact strict path="/swap" component={Swap} />
        <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
        <Route exact strict path="/pools" component={MyPools} />
        <Route
          exact
          strict
          path="/remove/:tokens"
          component={RedirectOldRemoveLiquidityPathStructure}
        />
        <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
        <Route component={RedirectPathSwapOnly} />
      </Switch>
    );
  };

  return (
    <>
      {greaterThan('lg') ? (
        <>
          <SideMenu
            logo={config.companyLogoUrl}
            title={config.companyName}
            subtitle={config.productName}
          />

          <div className="layout-content">
            <Header />
            <div className="content-wrapper">
              <div className="content">{account ? renderConnected() : renderNotConnected()}</div>
            </div>
          </div>
        </>
      ) : (
        <div className="mobile-wrapper">
          <div>
            <img src="/logo512.png" alt="logo" className="mt-8 mb-8" />
          </div>
          <div className="mb-4 text-2xl">Mobile support is coming soon!</div>
          <div>Please switch to desktop to use the DEX</div>
        </div>
      )}
    </>
  );
};

export default Layout;
