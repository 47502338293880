import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useActiveWeb3React } from 'hooks/useActiveWeb3React';

import { AppDispatch, AppState } from '../index';
import { initSDK } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { IWallet } from '@stichting-allianceblock-foundation/abdex-sdk-v2/dist/types/i-wallet-dex';
import { addSdk, clearSdk } from './actions';

export default function Updater(): null {
  const { library, active } = useActiveWeb3React();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!active || !library) return;
    async function initializeSdk() {
      try {
        const signer = await library?.getSigner();
        const sdk = await initSDK(signer as IWallet);
        dispatch(addSdk({ sdk: sdk }));
      } catch (e) {
        console.error('[Error trying to initialize the sdk]:', e);
        dispatch(clearSdk({ sdk: {} }));
      }
    }
    initializeSdk();
  }, [active, library, dispatch]);

  return null;
}
