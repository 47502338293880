import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useActiveWeb3React } from 'hooks/useActiveWeb3React';

import { AppDispatch, AppState } from '../index';
import { addPopup, PopupContent, updateCurrentNetwork, updateSideMenuOpen } from './actions';

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React();

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1]);
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch();

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }));
    },
    [dispatch],
  );
}

// returns a function with current network
export function useCurrentNetwork(): Network {
  return useSelector((state: AppState) => state.application.currentNetwork);
}

export function useCurrentNetworkHandlers(): {
  setCurrentNetwork: (currentNetwork: Network) => void;
} {
  const dispatch = useDispatch<AppDispatch>();

  const setCurrentNetwork = useCallback(
    (currentNetwork: Network) => {
      dispatch(updateCurrentNetwork({ currentNetwork }));
    },
    [dispatch],
  );

  return {
    setCurrentNetwork,
  };
}

// returns a function with current sidemenu status
export function useIsSideMenuOpen(): boolean {
  return useSelector((state: AppState) => state.application.isSideMenuOpen);
}

export function useSideMenuHandlers(): {
  setIsSideMenuOpen: (isOpen: boolean) => void;
} {
  const dispatch = useDispatch<AppDispatch>();

  const setIsSideMenuOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(updateSideMenuOpen({ isOpen }));
    },
    [dispatch],
  );

  return {
    setIsSideMenuOpen,
  };
}
