import {
  Currency,
  CurrencyAmount,
  EWT,
  MATIC,
  NativeCurrency,
  Percent,
  Price,
  Token,
  TokenAmount,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { Trade, TradePool } from '@stichting-allianceblock-foundation/abdex-trade-sdk';
import { Route } from '@stichting-allianceblock-foundation/abdex-trade-sdk/dist/base-classes/route';

export const unwrapCurrency = (currency: Currency) => {
  if (currency instanceof Token) {
    return {
      type: 'token',
      chainId: currency.chainId,
      address: currency.address,
      decimals: currency.decimals,
      symbol: currency.symbol,
      name: currency.name,
    };
  }

  let type = 'currency';

  if (currency === MATIC) type = 'matic';
  else if (currency === EWT) type = 'ewt';
  else {
    console.error(currency);
    throw new Error('Invalid currency');
  }

  return {
    type: type,
    decimals: currency.decimals,
    symbol: currency.symbol,
    name: currency.name,
  };
};

export const wrapCurrency = (currency: any) => {
  if (currency.type === 'token') {
    return new Token(
      currency.chainId,
      currency.address,
      currency.decimals,
      currency.name,
      currency.symbol,
    );
  }

  if (currency.type === 'matic') return MATIC;
  if (currency.type === 'ewt') return EWT;

  console.error(currency);
  throw new Error('Invalid currency');
};

export const unwrapCurrencyAmount = (currencyAmount: CurrencyAmount) => {
  if (currencyAmount instanceof TokenAmount) {
    return {
      type: 'token',
      currency: unwrapCurrency(currencyAmount.currency),
      raw: currencyAmount.raw.toString(),
      chainId: currencyAmount instanceof TokenAmount ? currencyAmount.token.chainId : undefined,
    };
  }

  if (currencyAmount instanceof NativeCurrency) {
    return {
      type: 'native',
      currency: unwrapCurrency(currencyAmount.currency),
      raw: currencyAmount.raw.toString(),
      chainId: currencyAmount instanceof TokenAmount ? currencyAmount.token.chainId : undefined,
    };
  }

  console.error(currencyAmount);
  throw new Error('Invalid currency amount');
};

export const wrapCurrencyAmount = (currencyAmount: any) => {
  if (currencyAmount.type === 'token') {
    return new TokenAmount(wrapCurrency(currencyAmount.currency) as Token, currencyAmount.raw);
  }

  if (currencyAmount.type === 'native') {
    return new NativeCurrency(currencyAmount.raw, wrapCurrency(currencyAmount.currency));
  }

  console.error(currencyAmount);
  throw new Error('Invalid currency amount');
};

export const unwrapTradePools = (tradePools: TradePool[]) =>
  tradePools.map(pool => {
    return {
      pool: {
        token0: unwrapCurrency(pool.pool.token0),
        token1: unwrapCurrency(pool.pool.token1),
        chainId: pool.pool.chainId,
      },
      poolReserveToken0: pool.poolReserveToken0,
      poolReserveToken1: pool.poolReserveToken1,
      tokenAddress0: pool.tokenAddress0,
      pair1Reserve0: pool.pair1Reserve0,
      pair1Reserve1: pool.pair1Reserve1,
      pair2Reserve0: pool.pair2Reserve0,
      pair2Reserve1: pool.pair2Reserve1,
      virtualPair1Split: pool.virtualPair1Split,
      virtualPair2Split: pool.virtualPair2Split,
      vPair1Spot: pool.vPair1Spot,
      vPair2Spot: pool.vPair2Spot,
      vPair1W: pool.vPair1W,
      vPair2W: pool.vPair2W,
      poolSpot: pool.poolSpot,
      gamma: pool.gamma,
      pair1Empty: pool.pair1Empty,
      pair2Empty: pool.pair2Empty,
    };
  });

export const wrapTradePools = (tradePools: any) => {
  for (const tradePool of tradePools) {
    tradePool.vPair1Empty = () => tradePool.pair1Empty;
    tradePool.vPair2Empty = () => tradePool.pair2Empty;
    tradePool.pool.token0 = wrapCurrency(tradePool.pool.token0);
    tradePool.pool.token1 = wrapCurrency(tradePool.pool.token1);
  }

  return tradePools;
};

export const unwrapRoute = (route: Route) => {
  return {
    input: unwrapCurrency(route.input),
    output: unwrapCurrency(route.output),
    midPrice: route.midPrice,
    path: route.path.map(unwrapCurrency),
    tradePools: unwrapTradePools(route.tradePools),
  };
};

export const wrapRoute = (route: any) => {
  route.input = wrapCurrency(route.input);
  route.output = wrapCurrency(route.output);
  route.path = route.path.map(wrapCurrency);
  route.tradePools = wrapTradePools(route.tradePools);

  return route;
};

export const unwrapTrade = (trade: Trade) => ({
  route: unwrapRoute(trade.route),
  tradeType: trade.tradeType,
  inputAmount: unwrapCurrencyAmount(trade.inputAmount),
  outputAmount: unwrapCurrencyAmount(trade.outputAmount),
  executionPrice: {
    baseCurrency: trade.executionPrice.baseCurrency,
    quoteCurrency: trade.executionPrice.quoteCurrency,
    denominator: trade.executionPrice.denominator.toString(),
    numerator: trade.executionPrice.numerator.toString(),
  },
  priceImpact: {
    denominator: trade.priceImpact.denominator.toString(),
    numerator: trade.priceImpact.numerator.toString(),
  },
});

export const wrapTrade = (trade: any) => {
  const instance: Trade = Object.create(Trade.prototype);

  instance.route = wrapRoute(trade.route);
  instance.tradeType = trade.tradeType;
  instance.inputAmount = wrapCurrencyAmount(trade.inputAmount);
  instance.outputAmount = wrapCurrencyAmount(trade.outputAmount);
  instance.executionPrice = new Price(
    trade.executionPrice.baseCurrency,
    trade.executionPrice.quoteCurrency,
    trade.executionPrice.denominator,
    trade.executionPrice.numerator,
  );
  instance.priceImpact = new Percent(trade.priceImpact.numerator, trade.priceImpact.denominator);

  return instance;
};
