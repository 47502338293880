import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import { InfoTooltip } from 'components/Tooltips';
import { SUPPORTED_WALLETS } from 'configs/constants';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { injectedConnector } from 'utils/web3React';

import { AppDispatch } from '../../state';
import { clearAllTransactions } from '../../state/transactions/actions';
import { getChainExplorerName, getEtherscanLink } from '../../utils';
import Transaction from './Transaction';

import './AccountDetails.scss';

function renderTransactions(transactions: string[]) {
  return (
    <div className="transaction-list-wrapper d-flex flex-column">
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />;
      })}
    </div>
  );
}

interface AccountDetailsProps {
  pendingTransactions: string[];
  confirmedTransactions: string[];
  ENSName?: string;
}

export default function AccountDetails({
  pendingTransactions,
  confirmedTransactions,
  ENSName,
}: AccountDetailsProps) {
  const { chainId, account, connector, deactivate } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  function formatConnectorName() {
    const { ethereum } = window;
    const isMetaMask = !!(ethereum && ethereum.isMetaMask);
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k =>
          SUPPORTED_WALLETS[k].connector === connector &&
          (connector !== injectedConnector || isMetaMask === (k === 'METAMASK')),
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0];
    return <div className="walletName">Connected with {name}</div>;
  }

  function getStatusIcon() {
    if (connector === injectedConnector) {
      return <div className="iconWrapper">{/* <Identicon /> */}</div>;
    }
    return null;
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }));
  }, [dispatch, chainId]);

  return (
    <>
      {!!pendingTransactions.length || !!confirmedTransactions.length ? (
        <div className="p-5 lower-section d-flex flex-column">
          <div className="flex-row mb-2 d-flex justify-space-between">
            <p className="mr-2 text-secondary text-bold">Recent Transactions</p>
          </div>
          {renderTransactions(pendingTransactions)}
          {renderTransactions(confirmedTransactions)}
        </div>
      ) : (
        <div className="p-5 lower-section">
          <div color="black">Your transactions will appear here...</div>
        </div>
      )}
    </>
  );
}
