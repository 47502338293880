import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { getCoinsList } from 'utils/coingecko';
import { getWalletConnected } from './useWalletConnection';

export const useCoinGeckoList = () => {
  const { activate, active, chainId } = useWeb3React();
  const [list, setList] = useState<Array<object>>([]);

  useEffect(() => {
    async function getList() {
      try {
        if (getWalletConnected()) {
          const data = await getCoinsList();
          setList(data);
        } else {
          return;
        }
      } catch (e) {
        console.error('[Error trying to connect to the coingecko]:', e);
      }
    }

    getList();
  }, [getWalletConnected(), chainId]);

  return list;
};
