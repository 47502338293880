import { AbstractConnector } from '@web3-react/abstract-connector';

import { BigNumber } from '@ethersproject/bignumber';
import {
  ChainId,
  Currency,
  JSBI,
  Percent,
  Token,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { injectedConnector } from 'utils/web3React';

export const localStorageKeys: { [key: string]: string } = {
  walletConnection: 'abdex:connected',
};

// Breakpoints taken from the components library
// https://github.com/Stichting-AllianceBlock-Foundation/components/blob/develop/src/styles/_variables.scss
export const breakpoints: { [breakpoint: string]: number } = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 991,
  xl: 1400,
};

export const NetworkContextName = 'NETWORK';

export const FACTORY_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.POLYGON]: '', // TODO ADD ADDRESS
  [ChainId.MATIC_MUMBAI]: '0x79bec327863876bAB33954af158215CfAaD09B5F',
  [ChainId.LOCAL]: '0x933cF2Aecc1c17CA1b3bd51557B253bB4bd02A12',
  [ChainId.VOLTA]: '0x2b82f14099590c2eF9d4e7747Ef479783Ec4e687',
  [ChainId.MAIN_EWC]: '0x4072c974327722859798ac1722Cf6Ca92431d343',
};

export const ROUTER_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.POLYGON]: '', // TODO ADD ADDRESS
  [ChainId.MATIC_MUMBAI]: '0x0a4e3068Ae04c679d846Bd1aB456398c191392cd',
  [ChainId.LOCAL]: '0x6E11978D2560027526f8E0545823E537c196370d',
  [ChainId.VOLTA]: '0x061ca14D7B114a4C56ff8d3D4272Cf2238510ba5',
  [ChainId.MAIN_EWC]: '0x6751c00E8A0E25c39175168DE4D34C8c9713cA30',
};

export const ENSREGISTRAR_ADDRESS = {
  [ChainId.POLYGON]: '',
  [ChainId.MATIC_MUMBAI]: '0xce7410a9F6C5F2B3a1dF10760D023D91bd8391b6', //Matic address
  [ChainId.LOCAL]: '',
  [ChainId.VOLTA]: '0x49a557373599E176b01224118c0C24c6246994b1', // TODO ADDRESS HERE
  [ChainId.MAIN_EWC]: '0xf7C5C4075eFFf391Bea918811E946721969c749C',
};

export const MULTICALL_NETWORKS2_ADDRESS = {
  [ChainId.POLYGON]: '',
  [ChainId.MATIC_MUMBAI]: '0xCfE79dFDE25BDcb8994E494A82e006e066a26230', // from https://github.com/makerdao/multicall/pull/26/files
  [ChainId.LOCAL]: '',
  [ChainId.VOLTA]: '0x37E49c610Db6f5BFcDfb8a9cA5E6ad07Da914c94', // from https://github.com/makerdao/multicall/pull/26/files
  [ChainId.MAIN_EWC]: '0x7BCBD78db6fe8C7EBcF38234AFD1ff6d78b3eAa3',
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injectedConnector,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injectedConnector,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
};

export const NATIVE_CURRENCY = {
  // TODO Add eth here
  [ChainId.MATIC_MUMBAI]: Currency.MATIC,
  [ChainId.POLYGON]: Currency.MATIC,
  [ChainId.VOLTA]: Currency.EWT,
  [ChainId.LOCAL]: Currency.MATIC,
  [ChainId.MAIN_EWC]: Currency.EWT,
};

export const CURRENCY_METHOD = {
  // TODO Add eth here
  [ChainId.MATIC_MUMBAI]: 'matic',
  [ChainId.POLYGON]: 'matic',
  [ChainId.VOLTA]: 'ewt',
  [ChainId.LOCAL]: 'matic',
  [ChainId.MAIN_EWC]: 'ewt',
};

export const WALBT_MATIC = new Token(
  ChainId.MATIC_MUMBAI,
  '0x6f6fa4a30414e10681d0dff987fef8dfef5137af',
  18,
  'WALBT',
  'Wrapped AllianceBlock Token',
);

export const MTST = new Token(
  ChainId.MATIC_MUMBAI,
  '0xbf06524b57111324a88a1aec54a8dd044ec8d818',
  18,
  'MTST',
  'MTST TEST',
);

export const WrappedNativeToken = {
  [ChainId.POLYGON]: new Token(
    ChainId.POLYGON,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [ChainId.MATIC_MUMBAI]: new Token(
    ChainId.MATIC_MUMBAI,
    '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    18,
    'WMATIC',
    'Wrapped Matic',
  ),
  [ChainId.VOLTA]: new Token(
    ChainId.VOLTA,
    '0xdb8b4264b1777e046267b4cc123f0c9e029ceb2c',
    18,
    'WEWT',
    'Wrapped EWT',
  ),
  [ChainId.LOCAL]: new Token(
    ChainId.LOCAL,
    '0x59f30a6703F8Af4FAeAe0e8AFaB14202222F335f',
    18,
    'WMATIC',
    'Wrapped Matic',
  ),
  [ChainId.MAIN_EWC]: new Token(
    ChainId.MAIN_EWC,
    '0x6b3bd0478df0ec4984b168db0e12a539cc0c83cd',
    18,
    'WEWT',
    'Wrapped EWT',
  ),
  // TODO Another TOKEN HERE
};

type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

const WETH_ONLY: ChainTokenList = {
  [ChainId.POLYGON]: [WrappedNativeToken[ChainId.POLYGON]],
  [ChainId.MATIC_MUMBAI]: [WrappedNativeToken[ChainId.MATIC_MUMBAI]],
  [ChainId.VOLTA]: [WrappedNativeToken[ChainId.VOLTA]],
  [ChainId.LOCAL]: [WrappedNativeToken[ChainId.LOCAL]],
  [ChainId.MAIN_EWC]: [WrappedNativeToken[ChainId.MAIN_EWC]],
};

export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.POLYGON]: [...WETH_ONLY[ChainId.POLYGON]],
  [ChainId.MATIC_MUMBAI]: [...WETH_ONLY[ChainId.MATIC_MUMBAI], WALBT_MATIC],
  [ChainId.VOLTA]: [...WETH_ONLY[ChainId.VOLTA]],
  [ChainId.LOCAL]: [...WETH_ONLY[ChainId.LOCAL]],
  [ChainId.MAIN_EWC]: [...WETH_ONLY[ChainId.MAIN_EWC]],
};

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MATIC_MUMBAI]: [
    [
      new Token(
        ChainId.MATIC_MUMBAI,
        '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
        8,
        'cDAI',
        'Compound Dai',
      ),
      new Token(
        ChainId.MATIC_MUMBAI,
        '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
        8,
        'cUSDC',
        'Compound USD Coin',
      ),
    ],
  ],
};

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.POLYGON]: [...WETH_ONLY[ChainId.POLYGON]],
  [ChainId.MATIC_MUMBAI]: [...WETH_ONLY[ChainId.MATIC_MUMBAI]],
};

export const WEIGHT = BigNumber.from('750000000000000000');
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH
export const TOKEN_SLIDER_PERCENTAGE_DECIMALS: number = 2;

// exports for internal consumption
export const ZERO = JSBI.BigInt(0);
export const ONE = JSBI.BigInt(1);
export const TWO = JSBI.BigInt(2);
export const THREE = JSBI.BigInt(3);
export const FIVE = JSBI.BigInt(5);
export const TEN = JSBI.BigInt(10);
export const _100 = JSBI.BigInt(100);
export const _997 = JSBI.BigInt(997);
export const _1000 = JSBI.BigInt(1000);

export const ZERO_PERCENT: Percent = new Percent('0');
export const ONE_HUNDRED_PERCENT: Percent = new Percent('1');

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);

export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000));

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MATIC_MUMBAI]: [...WETH_ONLY[ChainId.MATIC_MUMBAI], WALBT_MATIC],
};
