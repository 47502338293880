import { ISDK } from '@stichting-allianceblock-foundation/abdex-sdk-v2/dist/types/sdk';
import { createReducer } from '@reduxjs/toolkit';
import { addSdk, clearSdk } from './actions';

export interface sdkState {
  sdk: ISDK | null;
}

export const initialState: sdkState = {
  sdk: null,
};

export default createReducer(initialState, builder =>
  builder
    .addCase(addSdk, (state, action) => {
      state.sdk = action.payload.sdk;
    })
    .addCase(clearSdk, (state, action) => {
      state.sdk = null;
    }),
);
