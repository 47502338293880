import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse } from '@ethersproject/providers';
import {
  ChainId,
  Currency,
  currencyEquals,
  EWT,
  MATIC,
  TokenAmount,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import {
  Button,
  Icon,
  LabelButton,
  StepPanel,
} from '@stichting-allianceblock-foundation/components';
import { AddLiquidityTransactionInfo } from 'components/AddLiquidityTransactionInfo';
import { Attention } from 'components/Attention';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';
import { CurrencyInputPanel } from 'components/CurrencyInputPanel';
import { EstimationFee } from 'components/EstimationFee';
import { Title } from 'components/Title';
import { NATIVE_CURRENCY, ROUTER_ADDRESS, WEIGHT, WrappedNativeToken } from 'configs/constants';
import { useCurrency } from 'hooks/Tokens';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { useCoinGeckoList } from 'hooks/useCoinGeckoList';
import useTransactionDeadline from 'hooks/useTransactionDeadline';
import { useCurrentNetwork } from 'state/application/hooks';
import { Field } from 'state/mint/actions';
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from 'state/mint/hooks';
import { useTransactionAdder } from 'state/transactions/hooks';
import { useUserSlippageTolerance } from 'state/user/hooks';
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract,
  maxAmountSpend,
} from 'utils';
import { getTokenPrice } from 'utils/coingecko';
import { currencyId } from 'utils/currencyId';
import { wrappedCurrency } from 'utils/wrappedCurrency';

import './SelectAssets.scss';

interface SelectAssetsProps {
  currencyIdA?: string;
  currencyIdB?: string;
  history: any;
}

const SelectAssets = ({ currencyIdA, currencyIdB, history }: SelectAssetsProps) => {
  const { account, chainId, library } = useActiveWeb3React();
  const { t } = useTranslation();
  const [isActive, setActive] = useState<boolean>(false);
  const currentNetwork = useCurrentNetwork();
  const programmaticHistory = useHistory();
  const coinGeckoList = useCoinGeckoList();
  const [tokenAFiatPrice, setTokenAFiatPrice] = useState<number>(0);
  const [tokenBFiatPrice, setTokenBFiatPrice] = useState<number>(0);
  const [userExecutionError, setUserExecutionError] = useState<string | undefined>();

  const currencyA = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);

  const oneCurrencyIsWMATIC = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WrappedNativeToken[chainId])) ||
        (currencyB && currencyEquals(currencyB, WrappedNativeToken[chainId]))),
  );

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState();
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    isEmpty,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false); // clicked confirm

  // txn values
  const deadline = useTransactionDeadline(); // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance(); // custom from users
  const [txHash, setTxHash] = useState<string>('');

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity
      ? otherTypedValue
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmountSpend(currencyBalances[field]),
    };
  }, {});

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
    };
  }, {});

  // check whether the user has approved the router contract on the tokens
  const [approvalA, approveHashA, approveACallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_A],
    ROUTER_ADDRESS[chainId as ChainId],
  );
  const [approvalB, approveHashB, approveBCallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_B],
    ROUTER_ADDRESS[chainId as ChainId],
  );

  const [is_A_BtnPressed, setIs_A_BtnPressed] = useState(false);
  const [is_B_BtnPressed, setIs_B_BtnPressed] = useState(false);

  const approveACallbackHandler = () => {
    setIs_A_BtnPressed(true);

    approveACallback().catch((error: Error) => {
      console.debug('Metamask rejected token approval', error);
      setIs_A_BtnPressed(false);
    });
  };
  const approveBCallbackHandler = () => {
    setIs_B_BtnPressed(true);

    approveBCallback().catch((error: Error) => {
      console.debug('Metamask rejected token approval', error);
      setIs_B_BtnPressed(false);
    });
  };

  const addTransaction = useTransactionAdder();

  async function onAdd() {
    if (!chainId || !library || !account) return;
    const router = getRouterContract(chainId, library, account);

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts;
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return;
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        parsedAmountA,
        noLiquidity ? 0 : allowedSlippage,
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        parsedAmountB,
        noLiquidity ? 0 : allowedSlippage,
      )[0],
    };

    // const is_firstToken_A = () => {
    //   return pair?.firstTokenAddress === wrappedCurrency(currencyA, chainId)?.address;
    // };

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null;

    if (currencyA === MATIC || currencyA === EWT || currencyB === MATIC || currencyB === EWT) {
      const tokenBIsNative = currencyB === MATIC || currencyB === EWT;
      estimate = router.estimateGas.addLiquidityNative;
      method = router.addLiquidityNative;
      args = [
        wrappedCurrency(tokenBIsNative ? currencyA : currencyB, chainId)?.address ?? '', // token
        WEIGHT.toString(),
        (tokenBIsNative ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsNative ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsNative ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // matic min
        account,
        deadline.toHexString(),
      ];
      value = BigNumber.from((tokenBIsNative ? parsedAmountB : parsedAmountA).raw.toString());
    } else {
      estimate = router.estimateGas.addLiquidity;
      method = router.addLiquidity;
      args = [
        isEmpty
          ? wrappedCurrency(currencyA, chainId)?.address ?? ''
          : wrappedCurrency(currencyB, chainId)?.address ?? '', // TODO: remove all those checks for isEmpty when v3 contracts implemented ;)
        isEmpty
          ? wrappedCurrency(currencyB, chainId)?.address ?? ''
          : wrappedCurrency(currencyA, chainId)?.address ?? '',
        WEIGHT.toString(),
        isEmpty ? parsedAmountA.raw.toString() : parsedAmountB.raw.toString(),
        isEmpty ? parsedAmountB.raw.toString() : parsedAmountA.raw.toString(),
        isEmpty ? amountsMin[Field.CURRENCY_A].toString() : amountsMin[Field.CURRENCY_B].toString(),
        isEmpty ? amountsMin[Field.CURRENCY_B].toString() : amountsMin[Field.CURRENCY_A].toString(),
        account,
        deadline.toHexString(),
      ];
      value = null;
    }

    setAttemptingTxn(true);
    setUserExecutionError(undefined);
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then(response => {
          setTxHash(response.hash);

          addTransaction(response, {
            summary: {
              action: 'Add liquidity',
              details: {
                amount0: parsedAmounts[Field.CURRENCY_A]?.toSignificant(3),
                currency0: currencies[Field.CURRENCY_A],
                amount1: parsedAmounts[Field.CURRENCY_B]?.toSignificant(3),
                currency1: currencies[Field.CURRENCY_B],
              },
            },
          });

          response.wait().then(() => {
            setAttemptingTxn(false);
            programmaticHistory.push('/pools');
          });
        }),
      )
      .catch(error => {
        setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error);
          if (error.data.code === 3 || error.data.code === -32016) {
            setUserExecutionError('You should be whitelisted in order to add liquidity!');
          }
        }
      });
  }

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA);
      setIs_A_BtnPressed(false);
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`);
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`);
      }
    },
    [currencyIdB, history, currencyIdA],
  );
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB);
      setIs_B_BtnPressed(false);
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`);
        } else {
          history.push(`/add/${newCurrencyIdB}`);
        }
      } else {
        history.push(
          `/add/${
            currencyIdA ? currencyIdA : `${NATIVE_CURRENCY[chainId as ChainId].symbol}`
          }/${newCurrencyIdB}`,
        );
      }
    },
    [currencyIdA, history, currencyIdB],
  );

  useEffect(() => {
    if (error) {
      setActive(false);
    }
  }, [error]);

  useEffect(() => {
    setIs_A_BtnPressed(false);
    setIs_B_BtnPressed(false);
  }, [account]);

  useEffect(() => {
    const tokenIdA = coinGeckoList
      .filter((item: any) => item.symbol === currencies[Field.CURRENCY_A]?.symbol?.toLowerCase())
      .map((item: any) => item.id);
    const tokenIdB = coinGeckoList
      .filter((item: any) => item.symbol === currencies[Field.CURRENCY_B]?.symbol?.toLowerCase())
      .map((item: any) => item.id);

    const getPrices = async () => {
      if (tokenIdA[0]) {
        const tokenAPrice = await getTokenPrice(tokenIdA[0], 'usd');
        setTokenAFiatPrice(tokenAPrice);
      } else {
        setTokenAFiatPrice(0);
      }
      if (tokenIdB[0]) {
        const tokenBPrice = await getTokenPrice(tokenIdB[0], 'usd');
        setTokenBFiatPrice(tokenBPrice);
      } else {
        setTokenBFiatPrice(0);
      }
    };
    getPrices();
  }, [coinGeckoList, currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B]]);

  useEffect(() => {
    setUserExecutionError(undefined);
  }, [currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B]]);

  const isAmountSmall =
    formattedAmounts[Field.CURRENCY_A] == '0' || formattedAmounts[Field.CURRENCY_B] == '0';

  const renderStep = () => {
    return (
      <div className="mt-8 mb-5 my-md-7">
        <div className={`pairs-wrapper p-6 ${isActive ? 'info-activated' : ''}`}>
          <h2 className="text-subtitle text-bold">Add Liquidity</h2>
          <div className="d-flex flex-column flex-md-row align-items-center">
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(
                  maxAmounts[Field.CURRENCY_A]?.toExact() ?? '',
                  currencies[Field.CURRENCY_A],
                );
              }}
              onCurrencySelect={handleCurrencyASelect}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              showCommonBases
            />
            <Icon
              className={isValid ? 'edit-between m-4 valid' : 'edit-between m-4'}
              color="ui-secondary"
              name="edit-plus"
              size={24}
            ></Icon>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(
                  maxAmounts[Field.CURRENCY_B]?.toExact() ?? '',
                  currencies[Field.CURRENCY_B],
                );
              }}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              showCommonBases
            />
          </div>

          <div className="m-5 controls d-flex align-items-center justify-content-center">
            {(approvalA === ApprovalState.NOT_APPROVED ||
              approvalA === ApprovalState.PENDING ||
              approvalB === ApprovalState.NOT_APPROVED ||
              approvalB === ApprovalState.PENDING) &&
              isValid && (
                <div className="approvals-wrapper d-flex flex-column flex-md-row">
                  <div className="m-3 approval">
                    {approvalA !== ApprovalState.APPROVED &&
                      (approvalA !== ApprovalState.PENDING ? (
                        <Button
                          size="md"
                          type="primary"
                          className="custom-disabled-style-button"
                          onClick={approveACallbackHandler}
                          // @ts-ignore
                          disabled={approvalA === ApprovalState.PENDING || is_A_BtnPressed}
                        >
                          <Icon
                            className="mr-2"
                            color="ui-main-background"
                            name="nav-ok-s"
                            size={24}
                          />
                          <>Approve {currencies[Field.CURRENCY_A]?.symbol} spending</>
                        </Button>
                      ) : (
                        <LabelButton
                          type="primary"
                          loading={true}
                          loadingText={t('addLiquidity:processingText')}
                          label={
                            approveHashA ? (
                              <BlockExplorerBadge // TODO: Make it dynamically
                                title={'Etherscan'}
                                hash={approveHashA}
                                blockExplorer={currentNetwork.blockExplorerUrl}
                                type="tx"
                              />
                            ) : (
                              <span className="text-center">
                                {t('addLiquidity:waitingForConfirmationText')}
                              </span>
                            )
                          }
                        />
                      ))}
                  </div>
                  <div className="m-3 approval">
                    {approvalB !== ApprovalState.APPROVED &&
                      (approvalB !== ApprovalState.PENDING ? (
                        <Button
                          size="md"
                          type="primary"
                          className="custom-disabled-style-button"
                          onClick={approveBCallbackHandler}
                          // @ts-ignore
                          disabled={approvalB === ApprovalState.PENDING || is_B_BtnPressed}
                        >
                          <Icon
                            className="mr-2"
                            color="ui-main-background"
                            name="nav-ok-s"
                            size={24}
                          />
                          <>Approve {currencies[Field.CURRENCY_B]?.symbol} spending</>
                        </Button>
                      ) : (
                        <LabelButton
                          type="primary"
                          loading={true}
                          loadingText={t('addLiquidity:processingText')}
                          label={
                            approveHashB ? (
                              <BlockExplorerBadge
                                title={'Etherscan'}
                                hash={approveHashB}
                                blockExplorer={currentNetwork.blockExplorerUrl}
                                type="tx"
                              />
                            ) : (
                              <span className="text-center">
                                {t('addLiquidity:waitingForConfirmationText')}
                              </span>
                            )
                          }
                        />
                      ))}
                  </div>
                </div>
              )}
            {isValid &&
              approvalA === ApprovalState.APPROVED &&
              approvalB === ApprovalState.APPROVED &&
              (!attemptingTxn ? (
                <Button
                  size="md"
                  type="primary"
                  className="custom-disabled-style-button"
                  disabled={
                    !isValid ||
                    isAmountSmall ||
                    approvalA !== ApprovalState.APPROVED ||
                    approvalB !== ApprovalState.APPROVED
                  }
                  onClick={onAdd}
                >
                  <Icon className="mr-2" color="ui-main-background" name="edit-plus" size={24} />
                  <span>
                    {noLiquidity ? t('addLiquidity:addPair') : t('addLiquidity:addLiquidityTitle')}
                  </span>
                </Button>
              ) : (
                <LabelButton
                  type="primary"
                  loading={true}
                  loadingText={t('addLiquidity:processingText')}
                  label={
                    txHash ? (
                      <BlockExplorerBadge // TODO: Make it dynamically
                        title={'Etherscan'}
                        hash={txHash}
                        blockExplorer={currentNetwork.blockExplorerUrl}
                        type="tx"
                      />
                    ) : (
                      <span className="text-center">
                        {t('addLiquidity:waitingForConfirmationText')}
                      </span>
                    )
                  }
                />
              ))}
          </div>
          {/* {error && <div className="error">{error}</div>} */}
          {error && <Attention title={'Attention:'} description={error} color={true}></Attention>}
          {userExecutionError && (
            <Attention
              title={'Attention:'}
              description={userExecutionError}
              color={true}
            ></Attention>
          )}
          {isAmountSmall && !error && (
            <Attention
              title={'Attention:'}
              description={'Amount is too small'}
              color={true}
            ></Attention>
          )}
          {/* {isValid && !isActive && (
            <EstimationFee txFeeEstimation="123" txFeeEstimationCurrency="123" />
          )} */}
        </div>
        <div className="info-wrapper d-flex justify-content-center">
          {!isActive && (
            <Button
              size="sm"
              type="secondary"
              className="info-button"
              onClick={() => setActive(!isActive)}
              disabled={!isValid}
            >
              Info
              <Icon className="ml-2" color="brand-primary" name="chevron-double-down" size={14} />
            </Button>
          )}
        </div>
        {isActive && (
          <>
            <div className="p-4 panel">
              <AddLiquidityTransactionInfo
                currencies={currencies}
                noLiquidity={noLiquidity}
                poolTokenPercentage={poolTokenPercentage}
                price={price}
                liquidityMinted={liquidityMinted}
                tokenAFiatPrice={tokenAFiatPrice}
                tokenBFiatPrice={tokenBFiatPrice}
              ></AddLiquidityTransactionInfo>
            </div>
            <div className="d-flex justify-content-center info-wrapper">
              {isActive && (
                <Button
                  size="sm"
                  type="secondary"
                  className="info-button"
                  onClick={() => setActive(!isActive)}
                >
                  Info
                  <Icon className="ml-2" color="brand-primary" name="chevron-double-up" size={14} />
                </Button>
              )}
            </div>
          </>
        )}
        <Attention
          title={'attentionField:addLiquidityTitle'}
          description={'attentionField:addLiquidityDescription'}
        ></Attention>
      </div>
    );
  };

  return (
    <div className="mt-8">
      <StepPanel>
        <div className="d-flex flex-column justify-content-md-between">{renderStep()}</div>
      </StepPanel>
    </div>
  );
};

export default SelectAssets;
