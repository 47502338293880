import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import RemoveLiquidityStepper from 'components/RemoveLiquidityStepper/RemoveLiquidityStepper';

export default function RemoveLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  return (
    <>
      <RemoveLiquidityStepper
        currencyIdA={currencyIdA}
        currencyIdB={currencyIdB}
        history={history}
      />
    </>
  );
}
