import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';

import { Step, Stepper } from '@stichting-allianceblock-foundation/components';

import { InitialStep } from './Steps';
import SelectAssets from './Steps/SelectAssets';

import './AddLiquidityStepper.scss';

interface AddLiquidityStepperProps {
  currencyIdA?: string;
  currencyIdB?: string;
  history: any;
}

const AddLiquidityStepper = ({ currencyIdA, currencyIdB, history }: AddLiquidityStepperProps) => {
  const { account, chainId, library } = useWeb3React();
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    const determineStep = async () => {
      if (currencyIdA || currencyIdB) {
        setActiveStep(1);
      }
    };

    determineStep();
  }, [currencyIdA, currencyIdB]);

  return (
    <div className="my-4 add-liquidity-stepper">
      <Stepper>
        <Step activeStep={activeStep} stepNumber={0} subtitle="" title="">
          <InitialStep setActiveStep={setActiveStep} />
        </Step>
        <Step activeStep={activeStep} stepNumber={1} subtitle="" title="">
          <SelectAssets currencyIdA={currencyIdA} currencyIdB={currencyIdB} history={history} />
        </Step>
      </Stepper>
    </div>
  );
};

export default AddLiquidityStepper;
