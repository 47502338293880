import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { JSBI, Percent, Token } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { Pool } from '@stichting-allianceblock-foundation/abdex-sdk-v2/dist/dex-classes/pool';
import { Button, Icon } from '@stichting-allianceblock-foundation/components';
import CurrencyLogo from 'components/StyledComponents/CurrencyLogo';
import { InfoTooltip } from 'components/Tooltips';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { useTokenBalance } from 'state/wallet/hooks';
import { useTotalSupply } from 'userData/TotalSupply';
import { currencyId } from 'utils/currencyId';
import { unwrappedToken } from 'utils/wrappedCurrency';

import './PoolCard.scss';

interface PoolProps {
  pool: Pool;
}
const PoolCard = ({ pool }: PoolProps) => {
  const [active, setActive] = useState(false);
  const { account } = useActiveWeb3React();
  const history = useHistory();

  const currency0 = unwrappedToken(pool.token0);
  const currency1 = unwrappedToken(pool.token1);

  const liquidityToken = new Token(
    pool?.chainId,
    pool.address,
    pool.decimals,
    pool?.symbol,
    pool?.name,
  );

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, liquidityToken);
  const totalPoolTokens = useTotalSupply(liquidityToken);

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = userDefaultPoolBalance;

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;
  const [token0Deposited, token1Deposited] =
    !!pool &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pool.getLiquidityValue(pool.reserve0(), totalPoolTokens, userPoolBalance),
          pool.getLiquidityValue(pool.reserve1(), totalPoolTokens, userPoolBalance),
        ]
      : [undefined, undefined];

  return (
    <>
      {!active && (
        <div className="p-3 d-flex pool align-items-center">
          <div className="flex-1 tokens-name-symbol d-flex">
            <CurrencyLogo currency={currency0} size={'24px'} />
            <CurrencyLogo
              currency={currency1}
              size={'24px'}
              style={{ marginLeft: -10, marginRight: 10 }}
            />
            <p className="mr-2 text-secondary">{currency0.name}</p>
            <p className="text-bold">{currency0.symbol}</p>
            <p className="ml-2 mr-2">/</p>
            <p className="mr-2 text-secondary">{currency1.name}</p>
            <p className="text-bold">{currency1.symbol}</p>
          </div>
          <div className="liquidity-pool-token d-flex">
            <p className="mr-4 text-bold">
              {userPoolBalance ? userPoolBalance.toSignificant(4) : '0'}
            </p>
            <Icon name="coin-albt" size={18} className="mr-2" />
            <p className="text-uppercase text-secondary">LP</p>
          </div>
          <div className="ml-8 action-buttons">
            <Icon
              name="staking-withdraw"
              color="ui-secondary"
              size={18}
              className="mr-2"
              onClick={() =>
                history.push(`/remove/${currencyId(currency0)}/${currencyId(currency1)}`)
              }
            />
            <Icon
              name="edit-plus"
              color="ui-secondary"
              size={18}
              className="mr-2"
              onClick={() => history.push(`/add/${currencyId(currency0)}/${currencyId(currency1)}`)}
            />
            <Icon
              name="chevron-down"
              color="ui-secondary"
              size={18}
              className="mr-2"
              onClick={() => setActive(!active)}
            />
          </div>
        </div>
      )}
      {active && (
        <div className="active-container d-flex flex-column">
          <div className="p-3 d-flex pool align-items-center">
            <div className="flex-1 tokens-name-symbol d-flex">
              <CurrencyLogo currency={currency0} size={'24px'} />
              <CurrencyLogo
                currency={currency1}
                size={'24px'}
                style={{ marginLeft: -10, marginRight: 10 }}
              />
              <p className="mr-2 text-secondary">{currency0.name}</p>
              <p className="text-bold">{currency0.symbol}</p>
              <p className="ml-2 mr-2">/</p>
              <p className="mr-2 text-secondary">{currency1.name}</p>
              <p className="text-bold">{currency1.symbol}</p>
            </div>
            <div className="mr-8 liquidity-pool-token d-flex">
              <p className="mr-4 text-bold">
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '0'}
              </p>
              <Icon name="coin-albt" size={18} className="mr-2" />
              <p className="text-uppercase text-secondary">LP</p>
            </div>
            <div className="action-buttons">
              {/*buttons inside open menue*/}
              <Icon
                name="staking-withdraw"
                color="ui-secondary"
                size={18}
                className="mr-2"
                onClick={() =>
                  history.push(`/remove/${currencyId(currency0)}/${currencyId(currency1)}`)
                }
              />
              <Icon
                name="edit-plus"
                color="ui-secondary"
                size={18}
                className="mr-2"
                onClick={() =>
                  history.push(`/add/${currencyId(currency0)}/${currencyId(currency1)}`)
                }
              />
              <Icon
                name="chevron-down"
                color="ui-secondary"
                size={18}
                className="mr-2"
                onClick={() => setActive(!active)}
              />
            </div>
          </div>
          <div className="ml-5 mr-5 hr text-secondary" />
          <div className="p-4">
            <div className="mb-4 tokens">
              <div className="d-flex">
                <p className="text-small text-secondary text-bold">Tokens</p>
              </div>
              <div className="mr-2 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Icon name="staking-assets" size={16} color="ui-main" className="mr-2" />
                  <div>
                    <p className="text-main">Pooled {currency0.symbol}</p>
                  </div>
                </div>
                <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
                <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
                  <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
                  <div className="d-flex flex-column flex-md-row">
                    <div className="d-flex">
                      <p className="mr-3 text-bold">{token0Deposited?.toSignificant(6)}</p>
                      <CurrencyLogo currency={currency0} size={'18px'} />
                      <p className="ml-1 text-uppercase text-secondary">{currency0.symbol}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mr-2 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Icon name="staking-assets" size={16} color="ui-main" className="mr-2" />
                  <div>
                    <p className="text-main">Pooled {currency1.symbol}</p>
                  </div>
                </div>
                <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
                <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
                  <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
                  <div className="d-flex flex-column flex-md-row">
                    <div className="d-flex">
                      <p className="mr-3 text-bold">{token1Deposited?.toSignificant(6)}</p>
                      <CurrencyLogo currency={currency1} size={'18px'} />
                      <p className="ml-1 text-uppercase text-secondary">{currency1.symbol}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pool">
              <div className="d-flex">
                <p className="text-small text-secondary text-bold">Pool</p>
              </div>
              <div className="mr-2 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Icon name="staking-amount" size={16} color="ui-main" className="mr-2" />
                  <div>
                    <p className="text-main">Share of Pool</p>
                  </div>
                </div>
                <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
                <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
                  <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
                  <div className="d-flex flex-column flex-md-row">
                    <div className="d-flex">
                      <p className="mr-3 text-bold">
                        {' '}
                        {poolTokenPercentage
                          ? (poolTokenPercentage.toSignificant(4) === '0.00'
                              ? '<0.01'
                              : poolTokenPercentage.toSignificant(4)) + '%'
                          : '0'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mr-2 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Icon name="edit-download" size={16} color="ui-main" className="mr-2" />
                  <div>
                    <p className="text-main">You will receive</p>
                  </div>
                </div>
                <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
                <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
                  <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
                  <div className="d-flex flex-column flex-md-row">
                    <div className="d-flex">
                      <p className="mr-3 text-bold">
                        {userPoolBalance ? userPoolBalance.toSignificant(4) : '0'}
                      </p>
                      <Icon name="coin-albt" size={18} className="mr-2" />
                      <p className="ml-1 text-uppercase text-secondary">{liquidityToken.symbol}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-container d-flex justify-content-center align-items-center">
            <Button
              size="md"
              type="secondary"
              className="mr-4"
              onClick={() =>
                history.push(`/remove/${currencyId(currency0)}/${currencyId(currency1)}`)
              }
            >
              <Icon className="mr-2" color="brand-primary" name="staking-withdraw" size={24} />
              Withdraw liquidity
            </Button>
            <Button
              size="md"
              type="primary"
              className="ml-4"
              onClick={() => history.push(`/add/${currencyId(currency0)}/${currencyId(currency1)}`)}
            >
              <Icon className="mr-2" color="brand-primary" name="staking-price" size={24} />
              Add liquidity
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PoolCard;
