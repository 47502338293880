import React from 'react';

import { useBreakpoint } from 'hooks/useBreakpoint';

import './Indicator.scss';

interface IndicatorProps {
  title: string;
  value: number;
  type: 'primary' | 'secondary';
  className?: string;
}

const Indicator = ({ className, title, value, type }: IndicatorProps) => {
  const { greaterThan } = useBreakpoint();

  return (
    <div className="d-flex align-items-center">
      <div className="mr-4 text-main">{title}</div>
      <div className="px-5 py-3 indicator-value">{value}</div>
    </div>
  );
};

export default Indicator;
