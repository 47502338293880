import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BigNumber } from '@ethersproject/bignumber';
import { splitSignature } from '@ethersproject/bytes';
import { TransactionResponse } from '@ethersproject/providers';
import {
  ChainId,
  Currency,
  currencyEquals,
  EWT,
  MATIC,
  Percent,
  TokenAmount,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import {
  Button,
  Icon,
  LabelButton,
  Slider,
  StepButtons,
  StepPanel,
} from '@stichting-allianceblock-foundation/components';
import { Attention } from 'components/Attention';
import { BlockExplorerBadge } from 'components/BlockExplorerBadge';
import { CurrencyInputPanel } from 'components/CurrencyInputPanel';
import { EstimationFee } from 'components/EstimationFee';
import { RemoveLiquidityTransactionInfo } from 'components/RemoveLiquidityTransactionInfo';
import { Title } from 'components/Title';
import { NATIVE_CURRENCY, ROUTER_ADDRESS, WEIGHT, WrappedNativeToken } from 'configs/constants';
import { Contract } from 'ethers/lib/ethers';
import { useCurrency } from 'hooks/Tokens';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { usePairContract } from 'hooks/useContract';
import useTransactionDeadline from 'hooks/useTransactionDeadline';
import { useCurrentNetwork } from 'state/application/hooks';
import { Field } from 'state/burn/actions';
import { useBurnActionHandlers, useBurnState, useDerivedBurnInfo } from 'state/burn/hooks';
import { useTransactionAdder } from 'state/transactions/hooks';
import { useUserSlippageTolerance } from 'state/user/hooks';
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract,
  maxAmountSpend,
} from 'utils';
import { currencyId } from 'utils/currencyId';
import useDebouncedChangeHandler from 'utils/useDebouncedChangeHandler';
import { wrappedCurrency } from 'utils/wrappedCurrency';

import './WithdrawAssets.scss';

interface SelectAssetsProps {
  currencyIdA?: string;
  currencyIdB?: string;
  history: any;
}

const WithdrawAssets = ({ currencyIdA, currencyIdB, history }: SelectAssetsProps) => {
  const { t } = useTranslation();
  const currentNetwork = useCurrentNetwork();
  const programmaticHistory = useHistory();
  const [currencyA, currencyB] = [
    useCurrency(currencyIdA) ?? undefined,
    useCurrency(currencyIdB) ?? undefined,
  ];
  const { account, chainId, library } = useActiveWeb3React();
  const [tokenA, tokenB] = useMemo(
    () => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)],
    [currencyA, currencyB, chainId],
  );
  const [isActive, setActive] = useState<boolean>(false);

  // burn state
  const { independentField, typedValue } = useBurnState();
  const { pair, parsedAmounts, liquidityToken, error } = useDerivedBurnInfo(
    currencyA ?? undefined,
    currencyB ?? undefined,
  );
  const { onUserInput: _onUserInput } = useBurnActionHandlers();
  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showDetailed, setShowDetailed] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('');
  const deadline = useTransactionDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toSignificant(),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY
        ? typedValue
        : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A
        ? typedValue
        : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B
        ? typedValue
        : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  };
  // pair contract
  const pairContract: Contract | null = usePairContract(liquidityToken?.address);

  // allowance handling
  const [signatureData, setSignatureData] = useState<{
    v: number;
    r: string;
    s: string;
    deadline: number;
  } | null>(null);

  const [approval, approveHash, approveCallback] = useApproveCallback(
    parsedAmounts[Field.LIQUIDITY],
    ROUTER_ADDRESS[chainId as ChainId],
  );

  async function onAttemptToApprove() {
    if (!pairContract || !pair || !library || !deadline || !liquidityToken)
      throw new Error('missing dependencies');
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];

    if (!liquidityAmount) throw new Error('missing liquidity amount');

    // try to gather a signature for permission
    const nonce = await pairContract.nonces(account);
    const name = await pairContract.name();

    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' },
    ];
    const domain = {
      name: name,
      version: '1',
      chainId: chainId,
      verifyingContract: liquidityToken.address,
    };
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ];
    const message = {
      owner: account,
      spender: ROUTER_ADDRESS[chainId as ChainId],
      value: liquidityAmount.raw.toString(),
      nonce: nonce.toHexString(),
      deadline: deadline.toNumber(),
    };
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit,
      },
      domain,
      primaryType: 'Permit',
      message,
    });

    library
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then(signature => {
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: deadline.toNumber(),
        });
      })
      .catch(error => {
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        if (error?.code !== 4001) {
          approveCallback();
        }
      });
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSignatureData(null);
      return _onUserInput(field, typedValue);
    },
    [_onUserInput],
  );

  // advanced mode when user put directly into liquidity token
  // const onLiquidityInput = useCallback(
  //   (typedValue: string): void => {
  //     onUserInput(Field.LIQUIDITY, typedValue);
  //   },
  //   [onUserInput],
  // );

  const onCurrencyAInput = useCallback(
    (typedValue: string): void => {
      onUserInput(Field.CURRENCY_A, typedValue);
    },
    [onUserInput],
  );

  const onCurrencyBInput = useCallback(
    (typedValue: string): void => {
      onUserInput(Field.CURRENCY_B, typedValue);
    },
    [onUserInput],
  );

  // tx sending
  const addTransaction = useTransactionAdder();

  async function onRemove() {
    if (!chainId || !library || !account || !deadline || !pair)
      throw new Error('missing dependencies');
    const {
      [Field.CURRENCY_A]: currencyAmountA,
      [Field.CURRENCY_B]: currencyAmountB,
    } = parsedAmounts;
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts');
    }
    const router = getRouterContract(chainId, library, account);

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    };

    if (!currencyA || !currencyB) throw new Error('missing tokens');
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error('missing liquidity amount');

    const currencyBIsETH = currencyB === MATIC || currencyB === EWT;
    const oneCurrencyIsETH = currencyA === MATIC || currencyA === EWT || currencyBIsETH;

    if (!tokenA || !tokenB) throw new Error('could not wrap');

    let methodNames: string[], args: Array<string | string[] | number | boolean | BigNumber>;
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityNative'];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          WEIGHT.toString(),
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadline.toHexString(),
        ];
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity'];
        args = [
          pair.poolKey,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadline.toHexString(),
        ];
      }
    }
    // we have a signataure, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityNativeWithPermit'];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          WEIGHT,
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ];
      }
      // removeLiquidityWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit'];
        args = [
          pair.poolKey,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ];
      }
    } else {
      throw new Error(
        'Attempting to confirm without approval or a signature. Please contact support.',
      );
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map(methodName =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch(error => {
            console.error(`estimateGas failed`, methodName, args, error);
            return undefined;
          }),
      ),
    );

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(safeGasEstimate =>
      BigNumber.isBigNumber(safeGasEstimate),
    );

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.');
      alert(
        'There is a pending transaction or You may have signed a wrong transaction. Please redo actions',
      );
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation];
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation];

      setAttemptingTxn(true);
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
        .then((response: TransactionResponse) => {
          setTxHash(response.hash);

          addTransaction(response, {
            summary: {
              action: 'Withdraw liquidity',
              details: {
                amount0: parsedAmounts[Field.CURRENCY_A]?.toSignificant(3),
                currency0: currencyA,
                amount1: parsedAmounts[Field.CURRENCY_B]?.toSignificant(3),
                currency1: currencyB,
              },
            },
          });

          response.wait().then(() => {
            setAttemptingTxn(false);
            programmaticHistory.push('/pools');
          });
        })
        .catch((error: Error) => {
          setAttemptingTxn(false);
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(error);
        });
    }
  }

  // const pendingText = `Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
  //   currencyA?.symbol
  // } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`;

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString());
    },
    [onUserInput],
  );

  const oneCurrencyIsETH =
    currencyA === MATIC || currencyA === EWT || currencyB === MATIC || currencyB === EWT;
  const oneCurrencyIsWMATIC = Boolean(
    chainId &&
      ((currencyA && currencyEquals(WrappedNativeToken[chainId], currencyA)) ||
        (currencyB && currencyEquals(WrappedNativeToken[chainId], currencyB))),
  );

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/remove/${currencyId(currency)}/${currencyIdA}`);
      } else {
        history.push(`/remove/${currencyId(currency)}/${currencyIdB}`);
      }
    },
    [currencyIdA, currencyIdB, history],
  );
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/${currencyIdB}/${currencyId(currency)}`);
      } else {
        history.push(`/remove/${currencyIdA}/${currencyId(currency)}`);
      }
    },
    [currencyIdA, currencyIdB, history],
  );

  // const handleDismissConfirmation = useCallback(() => {
  //   setShowConfirm(false);
  //   setSignatureData(null); // important that we clear signature data to avoid bad sigs
  //   // if there was a tx hash, we want to clear the input
  //   if (txHash) {
  //     onUserInput(Field.LIQUIDITY_PERCENT, '0');
  //   }
  //   setTxHash('');
  // }, [onUserInput, txHash]);

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toSignificant(2)),
    liquidityPercentChangeCallback,
  );

  // intercept <error> from <useDerivedBurnInfo> hook and create a custom error
  let extendedError: any = undefined;
  if (error) {
    const L = parseFloat(formattedAmounts[Field.LIQUIDITY]);
    const A = parseFloat(formattedAmounts[Field.CURRENCY_A]);
    const B = parseFloat(formattedAmounts[Field.CURRENCY_B]);
    if (L > 0 || A > 0 || B > 0) {
      extendedError = 'Insufficient token balance';
    } else {
      extendedError = error;
    }
  }

  useEffect(() => {
    if (error) {
      setActive(false);
    }
  }, [error]);

  const renderStep = () => {
    return (
      <div className="mt-8 mb-5 my-md-7">
        <Title
          title={t('withdrawLiquidity:withdrawLiquidityTitle')}
          description={t('withdrawLiquidity:subtitle')}
        ></Title>
        <div className={`pairs-wrapper p-4 ${isActive ? 'info-activated' : ''}`}>
          <h2 className="text-subtitle text-bold">Withdraw Liquidity</h2>
          <div className="d-flex flex-column flex-md-row align-items-center">
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onCurrencyAInput}
              onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
              onCurrencySelect={handleSelectCurrencyA}
              currency={currencyA}
              id="add-liquidity-input-tokena"
              showCommonBases
              showMaxButton={true}
              disableCurrencySelect={true}
              hideSlider={true}
              hideBalance={true}
            />
            <Icon
              className="m-4 edit-between"
              color="ui-secondary"
              name="edit-plus"
              size={24}
            ></Icon>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onCurrencyBInput}
              onCurrencySelect={handleSelectCurrencyB}
              onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
              currency={currencyB}
              id="add-liquidity-input-tokenb"
              showCommonBases
              showMaxButton={true}
              disableCurrencySelect={true}
              hideSlider={true}
              hideBalance={true}
            />
          </div>
          <Slider
            color="primary"
            step={1}
            value={innerLiquidityPercentage}
            onChange={value => setInnerLiquidityPercentage(Number(value))}
          />

          <div className="m-5 controls d-flex align-items-center justify-content-center">
            {approval !== ApprovalState.PENDING && (
              <div className="m-3">
                <Button
                  size="md"
                  type="primary"
                  className="custom-disabled-style-button"
                  onClick={onAttemptToApprove}
                  disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                >
                  {approval === ApprovalState.APPROVED || signatureData !== null
                    ? 'Signed'
                    : 'Sign'}
                </Button>
              </div>
            )}
            {approval === ApprovalState.PENDING && (
              <div className="m-3">
                <LabelButton
                  type="primary"
                  loading={true}
                  loadingText={t('addLiquidity:processingText')}
                  label={
                    approveHash ? (
                      <BlockExplorerBadge // TODO: Make it dynamically
                        title={'Etherscan'}
                        hash={approveHash}
                        blockExplorer={currentNetwork.blockExplorerUrl}
                        type="tx"
                      />
                    ) : (
                      <span className="text-center">
                        {t('addLiquidity:waitingForConfirmationText')}
                      </span>
                    )
                  }
                />
              </div>
            )}
            {isValid &&
              (!attemptingTxn ? (
                <div className="m-3">
                  <Button
                    size="md"
                    type="primary"
                    className="custom-disabled-style-button"
                    disabled={
                      !isValid || !(approval === ApprovalState.APPROVED || signatureData !== null)
                    }
                    onClick={onRemove}
                  >
                    <Icon
                      className="mr-2"
                      color="ui-main-background"
                      name="staking-withdraw"
                      size={24}
                    />
                    <span>Withdraw Liquidity</span>
                  </Button>
                </div>
              ) : (
                <div className="m-3">
                  <LabelButton
                    type="primary"
                    loading={true}
                    loadingText={t('addLiquidity:processingText')}
                    label={
                      txHash ? (
                        <BlockExplorerBadge // TODO: Make it dynamically
                          title={'Etherscan'}
                          hash={txHash}
                          blockExplorer={currentNetwork.blockExplorerUrl}
                          type="tx"
                        />
                      ) : (
                        <span className="text-center">
                          {t('addLiquidity:waitingForConfirmationText')}
                        </span>
                      )
                    }
                  />
                </div>
              ))}
          </div>

          {/* {!isValid && <div className="error">{extendedError}</div>} */}
          {!isValid && (
            <Attention title={'Attention:'} description={extendedError} color={true}></Attention>
          )}
          {/* {isValid && !isActive && (
            <EstimationFee txFeeEstimation="123" txFeeEstimationCurrency="123" />
          )} */}
        </div>
        <div className="info-wrapper d-flex justify-content-center">
          {!isActive && (
            <Button
              size="sm"
              type="secondary"
              className="info-button"
              onClick={() => setActive(!isActive)}
              disabled={!isValid}
            >
              Info
              <Icon className="ml-2" color="brand-primary" name="chevron-double-down" size={14} />
            </Button>
          )}
        </div>
        {isActive && (
          <>
            <div className="p-4 panel">
              <RemoveLiquidityTransactionInfo
                currencyA={currencyA}
                currencyB={currencyB}
                liquidityToken={liquidityToken}
                pool={pair}
                parsedAmounts={parsedAmounts}
              ></RemoveLiquidityTransactionInfo>
            </div>
            <div className="d-flex justify-content-center info-wrapper">
              {isActive && (
                <Button
                  size="sm"
                  type="secondary"
                  className="info-button"
                  onClick={() => setActive(!isActive)}
                >
                  Info
                  <Icon className="ml-2" color="brand-primary" name="chevron-double-up" size={14} />
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="mt-8">
      <StepPanel>
        <div className="d-flex flex-column justify-content-md-between">{renderStep()}</div>
      </StepPanel>
    </div>
  );
};

export default WithdrawAssets;
