import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Currency,
  Percent,
  Price,
  TokenAmount,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { Icon } from '@stichting-allianceblock-foundation/components';
import CurrencyLogo from 'components/StyledComponents/CurrencyLogo';
import { InfoTooltip } from 'components/Tooltips';
import { ONE_BIPS } from 'configs/constants';
import { useCurrentNetwork } from 'state/application/hooks';
import { Field } from 'state/mint/actions';

import './AddLiquidityTransactionInfo.scss';

interface AddLiquidityTransactionInfo {
  currencies: { [field in Field]?: Currency };
  noLiquidity?: boolean;
  poolTokenPercentage?: Percent;
  price?: Price;
  liquidityMinted?: TokenAmount;
  tokenAFiatPrice: number;
  tokenBFiatPrice: number;
}

const AddLiquidityTransactionInfo = ({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
  liquidityMinted,
  tokenAFiatPrice,
  tokenBFiatPrice,
}: AddLiquidityTransactionInfo) => {
  const { t } = useTranslation();
  const currentNetwork = useCurrentNetwork();

  return (
    <div className="transactions-wrapper">
      <div className="mb-5 transaction-info">
        <div className="d-flex">
          <p className="text-small text-secondary text-uppercase text-bold">Transaction details</p>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <p className="mr-2">1</p>
            <CurrencyLogo currency={currencies[Field.CURRENCY_A]} size={'18px'} />
            <p className="ml-2 text-secondary">{currencies[Field.CURRENCY_A]?.symbol}</p>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <p className="mr-2">{price?.toSignificant(4) ?? '0.00'}</p>
            <CurrencyLogo currency={currencies[Field.CURRENCY_B]} size={'18px'} />
            <p className="ml-2 text-secondary">{currencies[Field.CURRENCY_B]?.symbol}</p>
          </div>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <p className="mr-2">1</p>
            <CurrencyLogo currency={currencies[Field.CURRENCY_B]} size={'18px'} />
            <p className="ml-2 text-secondary">{currencies[Field.CURRENCY_B]?.symbol}</p>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <p className="mr-2">{price?.invert().toSignificant(4) ?? '0.00'}</p>
            <CurrencyLogo currency={currencies[Field.CURRENCY_A]} size={'18px'} />
            <p className="ml-2 text-secondary">{currencies[Field.CURRENCY_A]?.symbol}</p>
          </div>
        </div>
      </div>

      <div className="mb-5 transaction-info">
        <div className="d-flex">
          <p className="text-small text-secondary text-bold">Pool</p>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">Share of pool</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <div className="d-flex flex-column flex-md-row">
              <p className="ml-2 text-bold">
                {noLiquidity && price
                  ? '100'
                  : (poolTokenPercentage?.lessThan(ONE_BIPS)
                      ? '<0.01'
                      : poolTokenPercentage?.toSignificant(4)) ?? '0'}
                %
              </p>
            </div>
          </div>
        </div>
        <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">You will receive</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <p className="mr-4 text-main text-bold">{liquidityMinted?.toSignificant(6)}</p>
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex">
                {currentNetwork && <Icon name="coin-albt" size={18} className="mr-2" />}
                <p className="text-uppercase text-secondary">ABLP</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5 transaction-info">
        <div className="d-flex">
          <p className="text-small text-secondary text-bold">Fees</p>
        </div>
        {/* <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">Network Fees</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex">
                {currentNetwork && (
                  <Icon name={currentNetwork?.chainIcon} size={18} className="mr-2" />
                )}
                <p className="text-uppercase text-secondary">
                  {currentNetwork?.nativeCurrency?.symbol}
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="mr-2 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="buying-fees" size={16} color="ui-main" className="mr-2" />
            <div>
              <p className="text-main">Liquidity provider fees</p>
            </div>
          </div>
          <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
          <div className="mt-4 d-flex justify-content-sm-start align-items-center mt-sm-0">
            <div className="mr-4 line d-flex d-sm-none flex-one mr-sm-0"></div>
            <div className="d-flex flex-column flex-md-row">
              <p className="ml-2 text-secondary">(0.3%)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLiquidityTransactionInfo;
