import { Currency, CurrencyAmount } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { Trade, TradePool } from '@stichting-allianceblock-foundation/abdex-trade-sdk';

import Worker from './route.worker';
import { unwrapCurrency, unwrapCurrencyAmount, unwrapTradePools, wrapTrade } from './wrap';

class Queue<T> {
  items: {
    action: () => Promise<T>;
    resolve: (value: T) => void;
    reject: (reason: unknown) => void;
  }[];
  pendingPromise: boolean;
  maxSize: number;

  constructor(maxSize: number) {
    this.items = [];
    this.pendingPromise = false;
    this.maxSize = maxSize;
  }

  enqueue(action: () => Promise<T>): Promise<T> {
    if (this.size > this.maxSize) {
      return new Promise((_, reject) => {
        reject(new Error('Queue is full'));
      });
    }

    return new Promise((resolve, reject) => {
      this.items.push({ action, resolve, reject });
      this.dequeue();
    });
  }

  async dequeue() {
    if (this.pendingPromise) return false;

    const item = this.items.shift();

    if (!item) return false;

    try {
      this.pendingPromise = true;

      const payload = await item.action();

      this.pendingPromise = false;
      item.resolve(payload);
    } catch (e) {
      this.pendingPromise = false;
      item.reject(e);
    } finally {
      this.dequeue();
    }

    return true;
  }

  get size() {
    return this.items.length;
  }
}

const queue = new Queue<Trade[]>(20);
const worker = new Worker();

export const bestTradeExactInWorker = (
  tradePools: TradePool[],
  currencyAmountIn: CurrencyAmount,
  currencyOut: Currency,
  maxHops: number,
  maxNumResults: number,
): Promise<Trade[]> => {
  if (process.env.REACT_APP_WORKER_DISABLED !== 'true' && window.Worker) {
    return queue.enqueue(
      () =>
        new Promise((resolve, reject) => {
          const start = Date.now();

          worker.postMessage({
            direction: 'in',
            tradePools: unwrapTradePools(tradePools),
            currencyAmountIn: unwrapCurrencyAmount(currencyAmountIn),
            currencyOut: unwrapCurrency(currencyOut),
            maxHops,
            maxNumResults,
          });

          const listener = function(event: MessageEvent) {
            if (event.data.error) {
              worker.removeEventListener('message', listener);
              reject(event.data.error);
              return;
            }

            const trades = event.data.map(wrapTrade);

            console.log('bestExactTradeIn worker took', Date.now() - start, 'ms');

            worker.removeEventListener('message', listener);
            resolve(trades);
          };

          worker.addEventListener('message', listener);
        }),
    );
  } else {
    return queue.enqueue(
      () =>
        new Promise((resolve, reject) => {
          try {
            const start = Date.now();
            const trades = Trade.bestTradeExactIn(tradePools, currencyAmountIn, currencyOut, {
              maxHops,
              maxNumResults,
            });
            console.log('bestExactTradeIn took', Date.now() - start, 'ms');

            resolve(trades);
          } catch (e) {
            reject(e);
          }
        }),
    );
  }
};

export const bestTradeExactOutWorker = (
  tradePools: TradePool[],
  currencyIn: Currency,
  currencyAmountOut: CurrencyAmount,
  maxHops: number,
  maxNumResults: number,
): Promise<Trade[]> => {
  if (process.env.REACT_APP_WORKER_DISABLED !== 'true' && window.Worker) {
    return queue.enqueue(
      () =>
        new Promise((resolve, reject) => {
          const start = Date.now();

          worker.postMessage({
            direction: 'out',
            tradePools: unwrapTradePools(tradePools),
            currencyIn: unwrapCurrency(currencyIn),
            currencyAmountOut: unwrapCurrencyAmount(currencyAmountOut),
            maxHops,
            maxNumResults,
          });

          const listener = function(event: MessageEvent) {
            if (event.data.error) {
              worker.removeEventListener('message', listener);
              reject(event.data.error);
              return;
            }

            const trades = event.data.map(wrapTrade);

            console.log('bestExactTradeOut worker took', Date.now() - start, 'ms');

            worker.removeEventListener('message', listener);
            resolve(trades);
          };

          worker.addEventListener('message', listener);
        }),
    );
  } else {
    return queue.enqueue(
      () =>
        new Promise((resolve, reject) => {
          try {
            const start = Date.now();
            const trades = Trade.bestTradeExactOut(tradePools, currencyIn, currencyAmountOut, {
              maxHops,
              maxNumResults,
            });
            console.log('bestExactTradeOut took', Date.now() - start, 'ms');

            resolve(trades);
          } catch (e) {
            reject(e);
          }
        }),
    );
  }
};
