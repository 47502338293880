import React from "react";

import { Icon } from "@stichting-allianceblock-foundation/components";
import { ClipboardTooltip } from "components/Tooltips/ClipboardTooltip";
import { useBreakpoint } from "hooks/useBreakpoint";
import { shortenHash } from "utils";

import "./BlockExplorerBadge.scss";

interface BlockExplorerBadgeProps {
  title?: string | JSX.Element;
  hash: string;
  blockExplorer: string;
  type: "address" | "tx";
  shorten?: boolean;
}

const BlockExplorerBadge = ({
  title,
  hash,
  blockExplorer,
  type,
  shorten,
}: BlockExplorerBadgeProps) => {
  const { lessThan } = useBreakpoint();
  const ADDRESS_OFFSET: number = 5;

  const generateBlockExplorerLink = (): string => {
    return `${blockExplorer}/${type}/${hash}`;
  };

  return (
    <div className="d-flex">
      <span className="text-secondary mx-2">
        {title
          ? title
          : shorten
          ? shortenHash(
              hash,
              lessThan("sm") ? ADDRESS_OFFSET * 2 : ADDRESS_OFFSET
            )
          : hash}
      </span>
      <ClipboardTooltip
        className="d-flex align-items-center mx-2 cursor-pointer"
        content={generateBlockExplorerLink()}
      >
        <Icon
          size={lessThan("sm") ? 20 : 14}
          name="edit-copy"
          color="ui-border"
        />
      </ClipboardTooltip>
      <a
        className="d-flex align-items-center ml-1"
        href={generateBlockExplorerLink()}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon
          size={lessThan("sm") ? 20 : 14}
          name="link-external-link"
          color="ui-border"
        />
      </a>
    </div>
  );
};

export default BlockExplorerBadge;
