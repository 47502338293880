import React, { useState } from 'react';
import { Token, Currency } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import styled from 'styled-components';
import { TYPE, CloseIcon } from 'theme';
import Card from '../Card';
import { AutoColumn } from '../Column';
import { RowBetween, RowFixed, AutoRow } from '../Row';
import CurrencyLogo from '../CurrencyLogo';
import { ArrowLeft, AlertTriangle } from 'react-feather';
import { transparentize } from 'polished';
import { useAddUserToken } from 'state/user/hooks';
import { getEtherscanLink } from 'utils';
import { useCombinedInactiveList } from 'state/lists/hooks';
import ListLogo from '../ListLogo';
import { PaddedColumn, Checkbox } from './styleds';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { ExternalLink } from 'components/ExternalLink';
import { Button } from '@stichting-allianceblock-foundation/components';
import './ImportToken.scss';

interface ImportProps {
  tokens: Token[];
  onBack?: () => void;
  onDismiss?: () => void;
  handleCurrencySelect?: (currency: Currency) => void;
}

export function ImportToken({ tokens, onBack, onDismiss, handleCurrencySelect }: ImportProps) {
  const { chainId } = useActiveWeb3React();

  const [confirmed, setConfirmed] = useState(false);

  const addToken = useAddUserToken();

  // use for showing import source on inactive tokens
  const inactiveTokenList = useCombinedInactiveList();

  // higher warning severity if either is not on a list
  const fromLists =
    (chainId && inactiveTokenList?.[chainId]?.[tokens[0]?.address]?.list) ||
    (chainId && inactiveTokenList?.[chainId]?.[tokens[1]?.address]?.list);

  return (
    <div className="wrapper">
      <PaddedColumn gap="14px" style={{ width: '100%', flex: '1 1' }}>
        <RowBetween>
          {onBack ? <ArrowLeft style={{ cursor: 'pointer' }} onClick={onBack} /> : <div></div>}
          <TYPE.mediumHeader>Import {tokens.length > 1 ? 'Tokens' : 'Token'}</TYPE.mediumHeader>
          {onDismiss ? <CloseIcon onClick={onDismiss} /> : <div></div>}
        </RowBetween>
      </PaddedColumn>
      <br />
      <PaddedColumn gap="md">
        {tokens.map(token => {
          const list = chainId && inactiveTokenList?.[chainId]?.[token.address]?.list;
          return (
            <div className="token-warning-container p-5">
              <AutoColumn gap="10px">
                <AutoRow align="center">
                  <CurrencyLogo currency={token} size={'24px'} />
                  <TYPE.body ml="8px" mr="8px" fontWeight={500}>
                    {token.symbol}
                  </TYPE.body>
                  <TYPE.darkGray fontWeight={300}>{token.name}</TYPE.darkGray>
                </AutoRow>
                {chainId && (
                  <ExternalLink href={getEtherscanLink(chainId, token.address, 'address')}>
                    <p>{token.address}</p>
                  </ExternalLink>
                )}
                {list !== undefined ? (
                  <RowFixed>
                    {list.logoURI && <ListLogo logoURI={list.logoURI} size="12px" />}
                    <TYPE.small ml="6px" color={'black'}>
                      via {list.name}
                    </TYPE.small>
                  </RowFixed>
                ) : (
                  <div className="warning-wrapper p-1">
                    <RowFixed>
                      <AlertTriangle stroke={'red'} size="10px" />
                      <TYPE.body color={'red'} ml="4px" fontSize="10px" fontWeight={500}>
                        Unknown Source
                      </TYPE.body>
                    </RowFixed>
                  </div>
                )}
              </AutoColumn>
            </div>
          );
        })}

        <Card
          style={{
            backgroundColor: fromLists ? transparentize(0.8, 'yellow') : transparentize(0.8, 'red'),
          }}
        >
          <AutoColumn
            justify="center"
            style={{ textAlign: 'center', gap: '16px', marginBottom: '12px' }}
          >
            <AlertTriangle stroke={fromLists ? 'yellow' : 'red'} size={32} />
            <TYPE.body fontWeight={600} fontSize={20} color={fromLists ? 'yellow' : 'red'}>
              Trade at your own risk!
            </TYPE.body>
          </AutoColumn>

          <AutoColumn style={{ textAlign: 'center', gap: '16px', marginBottom: '12px' }}>
            <TYPE.body fontWeight={400} color={fromLists ? 'yellow' : 'red'}>
              Anyone can create a token, including creating fake versions of existing tokens that
              claim to represent projects.
            </TYPE.body>
            <TYPE.body fontWeight={600} color={fromLists ? 'yellow' : 'red'}>
              If you purchase this token, you may not be able to sell it back.
            </TYPE.body>
          </AutoColumn>
          <AutoRow
            justify="center"
            style={{ cursor: 'pointer' }}
            onClick={() => setConfirmed(!confirmed)}
          >
            <Checkbox
              className=".understand-checkbox"
              name="confirmed"
              type="checkbox"
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
            />
            <TYPE.body
              ml="10px"
              fontSize="16px"
              color={fromLists ? 'yellow' : 'red'}
              fontWeight={500}
            >
              I understand
            </TYPE.body>
          </AutoRow>
        </Card>
        <Button
          size="md"
          type="primary"
          disabled={!confirmed}
          onClick={() => {
            tokens.map(token => addToken(token));
            handleCurrencySelect && handleCurrencySelect(tokens[0]);
          }}
        >
          Import
        </Button>
      </PaddedColumn>
    </div>
  );
}
