import SwapStepper from 'components/SwapStepper/SwapStepper';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export default function Swap({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  return (
    <>
      <SwapStepper currencyIdA={currencyIdA} currencyIdB={currencyIdB} history={history} />
    </>
  );
}
