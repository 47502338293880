import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Polling } from 'components/Polling';

import { Layout } from './components/Layout';

import './App.scss';
import './utils/i18next';

function App() {
  return (
    <Router>
      <Polling />
      <Layout />
    </Router>
  );
}

export default App;
