import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FilterDropDown from './FilterDropDown';

interface FilterStatus {
  name: string;
  icon: string;
}

function ClaimFilter() {
  const { account } = useWeb3React();

  const { t } = useTranslation();
  const [poolsFilterIndex, setPoolsFilterIndex] = useState(0);
  const [filterCriteria, setFilterCriteria] = useState<any>();

  const FILTER_SELECT_CONSTANTS = [
    {
      name: t('pools:filterOptions.constants.showAll'),
      icon: 'check',
      index: 0,
    },
  ];

  return (
    <div className="mb-4 mr-4 d-flex align-items-center width-sm-100 width-md-auto">
      <div className="d-flex">
        <FilterDropDown
          className="dropdown"
          optionsList={Object.values(FILTER_SELECT_CONSTANTS)?.map((item: FilterStatus) => item)}
          displayFields={{
            primary: 'name',
            secondary: '',
            icon: 'icon',
          }}
          size="sm"
          selected={poolsFilterIndex}
          onSelectOptionChange={option => {
            setFilterCriteria((prevState: any): void => {
              return {
                ...prevState,
                status: option.name,
              };
            });
            setPoolsFilterIndex(option.index);
          }}
        />
      </div>
    </div>
  );
}

export default ClaimFilter;
