import { BigNumber } from '@ethersproject/bignumber';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import {
  ChainId,
  Currency,
  CurrencyAmount,
  Fraction,
  JSBI,
  NativeCurrency,
  Token,
  TokenAmount,
  Percent,
  MATIC,
  EWT,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { TokenAddressMap } from 'state/lists/hooks';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { MIN_ETH, ROUTER_ADDRESS, TEN, TOKEN_SLIDER_PERCENTAGE_DECIMALS } from 'configs/constants';
import IRouter from '../configs/abis/IRouter.json';
import { ethers } from 'ethers';

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  80001: 'mumbai.polygonscan.com',
  137: 'polygonscan.com',
  73799: 'volta-explorer.energyweb.org',
  31337: 'mumbai.polygonscan.com',
  246: 'explorer.energyweb.org',
};

const SUPPORTED_CHAIN_EXPLORER: { [chainId in ChainId]: string } = {
  80001: 'Polygonscan',
  137: 'Polygonscan',
  73799: 'EnergyWeb Explorer',
  31337: 'Polygonscan',
  246: 'EnergyWeb Explorer',
};

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block',
): string {
  let prefix;
  if (chainId === ChainId.MATIC_MUMBAI) {
    prefix = `https://mumbai.polygonscan.com`;
  }

  // TODO: Add here all the prefixes for supported networks
  // if (chainId === ChainId.EWC_VOLTA) {
  //   prefix = 'https://volta-explorer.energyweb.org';
  // }
  else {
    prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[137]}`;
  }

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`;
    }
    case 'token': {
      return `${prefix}/token/${data}`;
    }
    case 'block': {
      return `${prefix}/block/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}

export function getChainExplorerName(chainId: ChainId): string {
  if (chainId in SUPPORTED_CHAIN_EXPLORER) {
    return SUPPORTED_CHAIN_EXPLORER[chainId];
  }
  throw new Error(`Unsupported network ID: ${chainId}`);
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string,
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === MATIC || currency === EWT) return true;
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address]);
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000));
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : currency === MATIC
        ? NativeCurrency.matic(JSBI.BigInt(typedValueParsed))
        : NativeCurrency.ewt(JSBI.BigInt(typedValueParsed));
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  // necessary for all paths to return a value
  return undefined;
}

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount): CurrencyAmount | undefined {
  if (!currencyAmount) return undefined;
  if (currencyAmount.currency === MATIC || currencyAmount.currency === EWT) {
    if (JSBI.greaterThan(currencyAmount.raw, MIN_ETH)) {
      if (currencyAmount.currency === MATIC) {
        return NativeCurrency.matic(JSBI.subtract(currencyAmount.raw, MIN_ETH));
      } else {
        return NativeCurrency.ewt(JSBI.subtract(currencyAmount.raw, MIN_ETH));
      }
    } else {
      if (currencyAmount.currency === MATIC) {
        return NativeCurrency.matic(JSBI.BigInt(0));
      } else {
        return NativeCurrency.ewt(JSBI.BigInt(0));
      }
    }
  }
  return currencyAmount;
}

export const toWei = (value: string, decimals?: number): BigNumber => parseUnits(value, decimals);

export const fromWei = (value: ethers.BigNumberish, decimals?: number): string =>
  formatUnits(value, decimals);

export const calculateTokenAmountByPercentage = (
  percentage: string,
  totalAmount: CurrencyAmount,
  currency: Currency,
): string => {
  const amount = toWei(percentage, currency.decimals)
    .mul(totalAmount.raw.toString())
    .div(toWei('1', currency.decimals + TOKEN_SLIDER_PERCENTAGE_DECIMALS));

  const amountResult = fromWei(amount.toString(), currency.decimals);
  return amountResult;
};

export const calculateTokenPercentageByAmount = (
  amount: string,
  totalAmount: CurrencyAmount,
  currency: Currency,
): string => {
  if (Number(totalAmount.raw.toString()) <= 0) {
    return '0';
  }
  // let amountBN;
  // try {
  //   amountBN = toWei(amount, currency.decimals);
  // } catch (err) {
  //   amountBN = toBN("0");
  // }
  let amountBN;
  try {
    amountBN = toWei(amount, currency.decimals);
  } catch (e) {
    return '0';
  }

  const calculatedPercentage = amountBN
    .mul(toWei('100', currency.decimals))
    .div(toWei(totalAmount.raw.toString(), currency.decimals));
  const percentage = calculatedPercentage.lte(100) ? calculatedPercentage : 100;
  return percentage.toString();
};

export const shortenHash = (hash: string, offset: number): string => {
  const len: number = hash?.length;
  return `${hash?.substring(0, offset)} ... ${hash?.substring(len - offset, len)}`;
};

export const copyToClipboard = (content: string) => {
  navigator.clipboard.writeText(content);
};

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[_ as ChainId], IRouter.abi, library, account);
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`);
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ];
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000));
}
