import { Summary } from './../transactions/hooks';
import { createAction } from '@reduxjs/toolkit';
import { TokenList } from 'configs/tokenInterfaces';

export type PopupContent =
  | {
      txn: {
        hash: string;
        success: boolean;
        summary?: Summary;
      };
    }
  | {
      listUpdate: {
        listUrl: string;
        oldList: TokenList;
        newList: TokenList;
        auto: boolean;
      };
    };

export enum ApplicationModal {
  WALLET,
  SETTINGS,
  SELF_CLAIM,
  ADDRESS_CLAIM,
  CLAIM_POPUP,
  MENU,
  DELEGATE,
  VOTE,
}

export const updateBlockNumber = createAction<{ chainId: number; blockNumber: number }>(
  'application/updateBlockNumber',
);
export const setOpenModal = createAction<ApplicationModal | null>('application/setOpenModal');
export const addPopup = createAction<{
  key?: string;
  removeAfterMs?: number | null;
  content: PopupContent;
}>('application/addPopup');
export const removePopup = createAction<{ key: string }>('application/removePopup');
export const updateCurrentNetwork = createAction<{ currentNetwork: Network }>(
  'application/updateCurrentNetwork',
);
export const updateSideMenuOpen = createAction<{ isOpen: boolean }>(
  'application/updateSideMenuOpen',
);
