import { useMemo } from 'react';

import { Interface } from '@ethersproject/abi';
import {
  ChainId,
  Currency,
  getPoolAddress,
  IPool as IPoolInterface,
  TokenAmount,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { useDexSdk } from 'state/sdk/hooks';

import IPool from '../configs/abis/IPool.json';
import { useMultipleContractSingleData } from '../state/multicall/hooks';
import { wrappedCurrency } from '../utils/wrappedCurrency';
import { FACTORY_ADDRESS, WEIGHT } from './../configs/constants';

const PAIR_INTERFACE = new Interface(IPool.abi);

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function usePairs(
  currencies: [Currency | undefined, Currency | undefined][],
): [PairState, IPoolInterface | null][] {
  const { chainId } = useActiveWeb3React();
  const sdk = useDexSdk();

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  );
  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB)
          ? getPoolAddress(FACTORY_ADDRESS[chainId as ChainId], tokenA, tokenB, WEIGHT)
          : undefined;
      }),
    [tokens],
  );
  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves');
  const firstToken: any = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'token0');

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result;
      const tokenA = tokens[i][0];
      const tokenB = tokens[i][1];
      if (loading || !sdk) return [PairState.LOADING, null];
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null];
      if (!reserves) return [PairState.NOT_EXISTS, null];
      if (!firstToken) return [PairState.NOT_EXISTS, null];
      if (!firstToken[i].result) return [PairState.NOT_EXISTS, null];
      if (!firstToken[i].result?.length) return [PairState.NOT_EXISTS, null];

      const firstTokenAddress = [...firstToken[i].result];

      const [token0, token1] =
        tokenA.address === firstTokenAddress[0] ? [tokenA, tokenB] : [tokenB, tokenA];

      return [
        PairState.EXISTS,
        new sdk.Pool(
          token0,
          token1,
          WEIGHT,
          new TokenAmount(token0, reserves[0].toString()),
          new TokenAmount(token1, reserves[1].toString()),
        ),
      ];
    });
  }, [results, firstToken, tokens]);
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, IPoolInterface | null] {
  return usePairs([[tokenA, tokenB]])[0];
}
