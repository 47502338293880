import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';

import { useCurrentNetworkHandlers } from 'state/application/hooks';
import { getMetamaskProvider } from 'utils/metamask';
import { getNetworkIndex } from 'utils/network';
import { ConnectorNames, connectorsByName } from 'utils/web3React';

import { useNetworkOptions } from './useNetworkOptions';
import { getWalletConnected } from './useWalletConnection';

export const useEagerConnect = () => {
  const { activate, active, chainId } = useWeb3React();
  const ethereum = getMetamaskProvider();
  const [tried, setTried] = useState<boolean>(false);
  const networkOptions = useNetworkOptions();
  const { setCurrentNetwork } = useCurrentNetworkHandlers();

  useEffect(() => {
    async function connectWallet() {
      const injectedConnector = connectorsByName[ConnectorNames.Injected];
      const isAuthorized: boolean = await injectedConnector.isAuthorized();

      try {
        if ((ethereum || isAuthorized) && getWalletConnected()) {
          await activate(injectedConnector, undefined, true);
          const index = getNetworkIndex(chainId!, networkOptions);
          setCurrentNetwork(networkOptions[index]);

          ethereum?.removeAllListeners(['networkChanged']);
        } else {
          setTried(true);
        }
      } catch (e) {
        console.error('[Error trying to connect to the wallet]:', e);
        setTried(true);
      }
    }

    connectWallet();
  }, [getWalletConnected(), chainId]);

  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  return tried;
};
