import {
  ChainId,
  Currency,
  CurrencyAmount,
  Token,
  TokenAmount,
  MATIC,
  EWT,
} from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { NATIVE_CURRENCY, WrappedNativeToken } from 'configs/constants';

export function wrappedCurrency(
  currency: Currency | undefined,
  chainId: ChainId | undefined,
): Token | undefined {
  return chainId && (currency === MATIC || currency === EWT)
    ? WrappedNativeToken[chainId]
    : currency instanceof Token
    ? currency
    : undefined;
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: ChainId | undefined,
): TokenAmount | undefined {
  const token =
    currencyAmount && chainId ? wrappedCurrency(currencyAmount.currency, chainId) : undefined;
  return token && currencyAmount ? new TokenAmount(token, currencyAmount.raw) : undefined;
}

export function unwrappedToken(token: Token): Currency {
  if (token.equals(WrappedNativeToken[token.chainId]))
    return NATIVE_CURRENCY[token.chainId as ChainId];
  return token;
}
