import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { ChainId } from '@stichting-allianceblock-foundation/abdex-sdk-v2';
import { NATIVE_CURRENCY } from 'configs/constants';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';

import AddLiquidity from './index';

export function RedirectPathAddLiquidityOnly({ location }: RouteComponentProps) {
  return <Redirect to={{ ...location, pathname: '/add' }} />;
}

export function RedirectPathSwapOnly({ location }: RouteComponentProps) {
  return <Redirect to={{ ...location, pathname: '/swap' }} />;
}

export function RedirectToAddLiquidity() {
  const { chainId } = useActiveWeb3React();

  return <Redirect to={`/add/${NATIVE_CURRENCY[chainId as ChainId].symbol}`} />;
}

const OLD_PATH_STRUCTURE = /^(0x[a-fA-F0-9]{40})-(0x[a-fA-F0-9]{40})$/;
export function RedirectOldAddLiquidityPathStructure(
  props: RouteComponentProps<{ currencyIdA: string }>,
) {
  const {
    match: {
      params: { currencyIdA },
    },
  } = props;
  const match = currencyIdA.match(OLD_PATH_STRUCTURE);
  if (match?.length) {
    return <Redirect to={`/add/${match[1]}/${match[2]}`} />;
  }
  return <AddLiquidity {...props} />;
}

export function RedirectDuplicateTokenIds(
  props: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>,
) {
  const {
    match: {
      params: { currencyIdA, currencyIdB },
    },
  } = props;
  if (currencyIdA.toLowerCase() === currencyIdB.toLowerCase()) {
    return <Redirect to={`/add/${currencyIdA}`} />;
  }
  return <AddLiquidity {...props} />;
}
