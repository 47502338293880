export const config: NetworkConfig = {
  polygon: {
    network: {
      symbol: 'MATIC',
      chainId: 137,
      chainName: 'Polygon Matic',
      chainIcon: 'matic',
      chainTargetId: 4,
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrl: 'https://rpc-mainnet.maticvigil.com',
      blockExplorerUrl: 'https://polygonscan.com',
      refreshClaimTimestamp: 15000,
    },
  },
  ewc: {
    network: {
      symbol: 'EWC',
      chainId: 246,
      chainName: 'Energy Web Chain',
      chainIcon: 'ewc',
      chainTargetId: 5,
      nativeCurrency: {
        name: 'EWT',
        symbol: 'EWT',
        decimals: 18,
      },
      rpcUrl: 'https://rpc.energyweb.org',
      blockExplorerUrl: 'https://explorer.energyweb.org',
      refreshClaimTimestamp: 15000,
    },
  },
};
