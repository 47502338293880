export const coingeckoCurrencies: { [currency: string]: string } = {
  usd: 'usd',
  eur: 'eur',
  cny: 'cny',
  jpy: 'jpy',
  rub: 'rub',
  gbp: 'gbp',
};

export const currencySymbols: { [currency: string]: string } = {
  usd: '$',
  eur: '€',
  cny: '¥',
  jpy: '¥',
  rub: '₽',
  gbp: '£',
};

export const coingeckoAPI: string = 'https://api.coingecko.com/api/v3';

// returns coingecko id given the token symbol
export const getCoingeckooIds = (symbol: string): CoinGeckoId => {
  switch (symbol) {
    case 'albt':
    case 'walbt':
      return {
        id: 'allianceblock',
        symbol: 'albt',
        name: 'AllianceBlock',
      };
    case 'eth':
    case 'aeth':
      return {
        id: 'ethereum',
        symbol: 'eth',
        name: 'Ethereum',
      };
    case 'bnb':
      return {
        id: 'binancecoin',
        symbol: 'bnb',
        name: 'Binance Coin',
      };
    case 'avax':
      return {
        id: 'avalanche-2',
        symbol: 'AVAX',
        name: 'Avalanche',
      };
    case 'matic':
      return {
        id: 'matic-network',
        symbol: 'matic',
        name: 'Polygon',
      };
    case 'ewc':
    case 'ewt':
    case 'wewt':
    case 'wabewt':
      return {
        id: 'energy-web-token',
        symbol: 'ewt',
        name: 'Energy Web Token',
      };
    case 'sgb':
    case 'wsgb':
      return {
        id: 'songbird',
        symbol: 'sgb',
        name: 'Songbird',
      };
    case 'aurora':
      return {
        id: 'aurora-near',
        symbol: 'aurora',
        name: 'Aurora',
      };
    case 'dev':
      return {
        id: 'moonbeam',
        symbol: 'glmr',
        name: 'Moonbeam',
      };
    case 'ftm':
      return { id: 'fantom', symbol: 'ftm', name: 'Fantom' };
    default:
      return {} as CoinGeckoId;
  }
};

export const getTokenPrice = async (tokenId: string, currency: string): Promise<number> => {
  const response = await fetch(
    `${coingeckoAPI}/simple/price?ids=${tokenId}&vs_currencies=${currency}`,
  );
  const data = await response.json();
  return data[tokenId][currency];
};

export const getCoinsList = async () => {
  const response = await fetch(`${coingeckoAPI}/coins/list`);
  const data = await response.json();
  return data;
};
